import { Transaction } from '@solana/web3.js';
import { ErrorCode, logger } from '@civic/common-gateway-react';
export const createSolanaTransactionFromBase64 = (partiallySignedTx) => Transaction.from(Buffer.from(partiallySignedTx, 'base64'));
const insufficientFundsMessages = ['insufficient funds', 'custom program error: 0x1'];
const isClientWalletFundsError = (error) => {
    if (insufficientFundsMessages.map((msg) => { var _a; return (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(msg); }).includes(true)) {
        return true;
    }
    return false;
};
// error: WalletSignMessageError: User rejected the request
const isUserRejectedError = (error) => {
    var _a, _b, _c;
    return !!(((_a = error.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('walletsignmessageerror')) ||
        ((_b = error.message) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes('walletsignmessageerror')) ||
        ((_c = error.message) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes('user rejected')));
};
export const parseTransactionErrorCode = (error) => {
    if (isClientWalletFundsError(error)) {
        logger.warn('parseTransactionErrorCode - INSUFFICIENT_FUNDS error detected', error);
        return ErrorCode.INSUFFICIENT_FUNDS;
    }
    if (isUserRejectedError(error)) {
        logger.warn('parseTransactionErrorCode - SIGN_TRANSACTION_ERROR error detected', error);
        return ErrorCode.SIGN_TRANSACTION_ERROR;
    }
    return undefined;
};
