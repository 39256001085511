import { ExtendedGatewayStatus, GatekeeperAPIStatus, GatewayStatus, RefreshTokenState, TokenIssuanceState, } from '../types';
import { prefixLogger } from '../logger';
import { getCivicPassSrcUrl } from './useCivicPass';
import { autoShowIframe } from '../utils/status';
import { gatewayStatusIfAllowed } from '../useReducer/utils';
const logDebug = prefixLogger('useGetGatekeeperRecord').debug;
const logError = prefixLogger('useGetGatekeeperRecord').error;
export const isRefreshFlow = (state) => !!state.gatewayToken;
const civicPassPayload = (state, gatekeeperRecordResponse) => {
    logDebug('civicPassPayload', { gatekeeperRecordResponse });
    const requestPayload = gatekeeperRecordResponse === null || gatekeeperRecordResponse === void 0 ? void 0 : gatekeeperRecordResponse.payload;
    return Object.assign(Object.assign({}, state.civicPass), (requestPayload ? { requestPayload } : {}));
};
export const reducer = (state, action) => {
    var _a, _b;
    logDebug('reducer', { state, action });
    switch (action.type) {
        case 'getGatekeeperRecord':
            return Object.assign(Object.assign({}, state), { gatekeeperRecordState: GatekeeperAPIStatus.REQUESTING });
        case 'getGatekeeperRecord_failure':
            return Object.assign(Object.assign({}, state), { iframeMinimized: !autoShowIframe(state), renderIframe: true, gatewayStatus: gatewayStatusIfAllowed(GatewayStatus.ERROR, Object.assign(Object.assign({}, state), { gatekeeperRecordState: action.gatekeeperRecord.state })), iframeSrcUrl: getCivicPassSrcUrl(Object.assign(Object.assign({}, state), { gatekeeperRecordState: action.gatekeeperRecord.state }), GatewayStatus.ERROR), gatekeeperRecordState: action.gatekeeperRecord.state });
        case 'getGatekeeperRecord_rejected':
            return Object.assign(Object.assign(Object.assign({}, state), { gatekeeperRecordState: action.gatekeeperRecord.state, 
                // we don't add any iframeSrcUrl because a flow will be in progress
                civicPass: Object.assign(Object.assign({}, state.civicPass), civicPassPayload(state, action.gatekeeperRecord)) }), (isRefreshFlow(state)
                ? { refreshTokenState: RefreshTokenState.FAILED }
                : { tokenIssuanceState: TokenIssuanceState.FAILED }));
        case 'getGatekeeperRecord_success':
            return Object.assign(Object.assign({}, state), { gatekeeperRecordState: action.gatekeeperRecord.state, 
                // gatewayStatusIfAllowed will handle the case where token is ACTIVE, EXPIRED etc.
                gatewayStatus: gatewayStatusIfAllowed(GatewayStatus.NOT_REQUESTED, Object.assign(Object.assign({}, state), { gatekeeperRecordState: action.gatekeeperRecord.state })), 
                // we don't add any iframeSrcUrl because a flow will be in progress
                civicPass: Object.assign(Object.assign({}, state.civicPass), civicPassPayload(state, action.gatekeeperRecord)) });
        case 'getGatekeeperRecord_location_not_supported':
        case 'getGatekeeperRecord_issued_location_not_supported':
            return Object.assign(Object.assign({}, state), { iframeMinimized: !autoShowIframe(state), renderIframe: true, iframeSrcUrl: getCivicPassSrcUrl(state, GatewayStatus.LOCATION_NOT_SUPPORTED), gatewayStatus: GatewayStatus.LOCATION_NOT_SUPPORTED, gatekeeperRecordState: action.gatekeeperRecord.state, civicPass: Object.assign(Object.assign({}, state.civicPass), civicPassPayload(state, action.gatekeeperRecord)) });
        case 'getGatekeeperRecord_vpn_not_supported':
        case 'getGatekeeperRecord_issued_vpn_not_supported':
            return Object.assign(Object.assign({}, state), { iframeMinimized: !autoShowIframe(state), renderIframe: true, iframeSrcUrl: getCivicPassSrcUrl(state, GatewayStatus.VPN_NOT_SUPPORTED), gatewayStatus: GatewayStatus.VPN_NOT_SUPPORTED, gatekeeperRecordState: action.gatekeeperRecord.state, civicPass: Object.assign(Object.assign({}, state.civicPass), civicPassPayload(state, action.gatekeeperRecord)) });
        case 'getGatekeeperRecord_retries_exhausted':
            return Object.assign(Object.assign({}, state), { iframeMinimized: !autoShowIframe(state), renderIframe: true, iframeSrcUrl: getCivicPassSrcUrl(state, GatewayStatus.ERROR), gatewayStatus: GatewayStatus.ERROR, gatekeeperRecordState: action.gatekeeperRecord.state });
        case 'getGatekeeperRecord_not_found':
            return Object.assign(Object.assign({}, state), { gatekeeperRecordState: action.gatekeeperRecord.state, gatewayStatus: gatewayStatusIfAllowed(GatewayStatus.NOT_REQUESTED, Object.assign(Object.assign({}, state), { gatekeeperRecordState: action.gatekeeperRecord.state })) });
        case 'getGatekeeperRecord_in_partner_review':
            return Object.assign(Object.assign({}, state), { iframeMinimized: !autoShowIframe(state), renderIframe: true, iframeSrcUrl: getCivicPassSrcUrl(state, GatewayStatus.IN_REVIEW), gatewayStatus: ExtendedGatewayStatus.TOKEN_IN_PARTNER_REVIEW, gatekeeperRecordState: action.gatekeeperRecord.state, tokenIssuanceState: TokenIssuanceState.IN_PARTNER_REVIEW, pending: (_b = (_a = action.gatekeeperRecord) === null || _a === void 0 ? void 0 : _a.payload) === null || _b === void 0 ? void 0 : _b.pending, civicPass: Object.assign(Object.assign({}, state.civicPass), civicPassPayload(state, action.gatekeeperRecord)) });
        default:
            return state;
    }
};
const useGetGatekeeperRecord = ({ wallet, gatekeeperClient, }, dispatch) => {
    const dispatchFetch = () => ({
        type: 'getGatekeeperRecord',
    });
    const dispatchFailure = () => ({
        type: 'getGatekeeperRecord_failure',
        gatekeeperRecord: { state: GatekeeperAPIStatus.SERVER_FAILURE, payload: undefined },
    });
    const dispatchRejected = (record) => ({
        type: 'getGatekeeperRecord_rejected',
        gatekeeperRecord: record,
    });
    const dispatchSuccess = (record) => ({
        type: 'getGatekeeperRecord_success',
        gatekeeperRecord: record,
    });
    const dispatchLocationNotSupported = (record) => ({
        type: 'getGatekeeperRecord_location_not_supported',
        gatekeeperRecord: record,
    });
    const dispatchIssuedLocationNotSupported = (record) => ({
        type: 'getGatekeeperRecord_issued_location_not_supported',
        gatekeeperRecord: record,
    });
    const dispatchVpnNotSupported = (record) => ({
        type: 'getGatekeeperRecord_vpn_not_supported',
        gatekeeperRecord: record,
    });
    const dispatchIssuedVpnNotSupported = (record) => ({
        type: 'getGatekeeperRecord_issued_vpn_not_supported',
        gatekeeperRecord: record,
    });
    const dispatchRetriesExhausted = (record) => ({
        type: 'getGatekeeperRecord_retries_exhausted',
        gatekeeperRecord: record,
    });
    const dispatchRecordNotFound = (record) => ({
        type: 'getGatekeeperRecord_not_found',
        gatekeeperRecord: record,
    });
    const dispatchInIssuancePartnerReview = (record) => ({
        type: 'getGatekeeperRecord_in_partner_review',
        gatekeeperRecord: record,
    });
    const getGatekeeperRecordResponseAction = (gatekeeperRecordResp) => {
        var _a;
        logDebug('getGatekeeperRecordResponseAction', gatekeeperRecordResp);
        const actions = {
            [GatekeeperAPIStatus.REJECTED]: () => dispatchRejected(gatekeeperRecordResp),
            [GatekeeperAPIStatus.REQUESTED]: () => dispatchSuccess(gatekeeperRecordResp),
            [GatekeeperAPIStatus.ISSUED]: () => dispatchSuccess(gatekeeperRecordResp),
            [GatekeeperAPIStatus.ISSUED_EXPIRED]: () => dispatchSuccess(gatekeeperRecordResp),
            [GatekeeperAPIStatus.ISSUED_EXPIRY_APPROACHING]: () => dispatchSuccess(gatekeeperRecordResp),
            [GatekeeperAPIStatus.ISSUED_LOCATION_NOT_SUPPORTED]: () => dispatchIssuedLocationNotSupported(gatekeeperRecordResp),
            [GatekeeperAPIStatus.ISSUED_VPN_NOT_SUPPORTED]: () => dispatchIssuedVpnNotSupported(gatekeeperRecordResp),
            [GatekeeperAPIStatus.VPN_NOT_SUPPORTED]: () => dispatchVpnNotSupported(gatekeeperRecordResp),
            [GatekeeperAPIStatus.LOCATION_NOT_SUPPORTED]: () => dispatchLocationNotSupported(gatekeeperRecordResp),
            [GatekeeperAPIStatus.NOT_REQUESTED]: () => dispatchRecordNotFound(gatekeeperRecordResp),
            [GatekeeperAPIStatus.REQUESTED_RETRIES_EXHAUSTED]: () => dispatchRetriesExhausted(gatekeeperRecordResp),
        };
        // handle the 'pending' object on the requested response: it's a special subset of 'REQUESTED'
        if (gatekeeperRecordResp &&
            (gatekeeperRecordResp === null || gatekeeperRecordResp === void 0 ? void 0 : gatekeeperRecordResp.state) === GatekeeperAPIStatus.REQUESTED &&
            ((_a = gatekeeperRecordResp.payload) === null || _a === void 0 ? void 0 : _a.pending)) {
            return () => dispatchInIssuancePartnerReview(gatekeeperRecordResp);
        }
        return actions[gatekeeperRecordResp === null || gatekeeperRecordResp === void 0 ? void 0 : gatekeeperRecordResp.state];
    };
    /**
     * Check to see if there is getGatekeeperRecord and dispatch actions based on the state of the record.
     * If the service call fails dispatch a failure.
     */
    const dispatchGatekeeperRecord = async (abortController) => {
        if (!wallet || !wallet.publicKey || !gatekeeperClient) {
            return;
        }
        logDebug('Fetching Gatekeeper record...', {
            publicKey: wallet.publicKey,
            isAborted: abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted,
        });
        dispatch(dispatchFetch());
        try {
            const record = await gatekeeperClient.getGatekeeperRecordWithPayload(wallet.publicKey);
            if (abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted) {
                return;
            }
            logDebug('Gatekeeper record response state: ', (record === null || record === void 0 ? void 0 : record.state) && GatekeeperAPIStatus[record === null || record === void 0 ? void 0 : record.state]);
            if (!record || !(record === null || record === void 0 ? void 0 : record.state) || (record === null || record === void 0 ? void 0 : record.state) === GatekeeperAPIStatus.SERVER_FAILURE) {
                dispatch(dispatchFailure());
                return;
            }
            const action = getGatekeeperRecordResponseAction(record);
            logDebug('Gatekeeper record action: ', action);
            if (!action) {
                logError('Cannot dispatch action for invalid Gatekeeper Record State.', { record });
                dispatch(dispatchFailure());
                return;
            }
            dispatch(action());
        }
        catch (error) {
            logError('Failed to fetch Gatekeeper record', error);
            dispatch(dispatchFailure());
        }
    };
    return { dispatchGatekeeperRecord, getGatekeeperRecordResponseAction, dispatchFailure };
};
export default useGetGatekeeperRecord;
