import fetchBuilder from 'fetch-retry';
import { GatekeeperAPIStatus } from '../types';
import { prefixLogger } from '../logger';
import { getDefaultApiNumRetries } from '../config';
import { objectToURLParams } from './generalUtils';
export const isGkApiStatusTokenCreated = (code) => code === GatekeeperAPIStatus.ISSUED;
export const isGkApiStatusTokenPending = (code) => code === GatekeeperAPIStatus.REQUESTED;
export const isGkApiStatusRequestedRetriesExhausted = (code) => code === GatekeeperAPIStatus.REQUESTED_RETRIES_EXHAUSTED;
export const isGkApiStatusFailure = (code) => !isGkApiStatusTokenPending(code) && !isGkApiStatusRequestedRetriesExhausted(code) && code >= 400;
const logDebug = prefixLogger('GatekeeperClient').debug;
const logError = prefixLogger('GatekeeperClient').error;
export default class GatekeeperClient {
    constructor(initConfig, flowId) {
        this.initConfig = initConfig;
        this.abortController = new AbortController();
        this.baseUrl = initConfig.baseUrl;
        this.stage = initConfig.stage;
        this.queryParams = initConfig.queryParams;
        this.headers = objectToURLParams(Object.assign(Object.assign({}, initConfig.headers), { 'x-civic-flowid': flowId }));
        this.fetchImplementation = initConfig.fetchImplementation || fetch;
        const retries = initConfig.numRetries || getDefaultApiNumRetries(this.stage);
        // By default retry on every 5xx or other Error (e.g. network failure):
        this.defaultRetryParams = {
            retries,
            retryOn: async (attempt, error, response) => {
                var _a;
                const body = response ? await response.clone().json() : {};
                if (response && response.status >= 500) {
                    if ((_a = body === null || body === void 0 ? void 0 : body.message) === null || _a === void 0 ? void 0 : _a.includes('NO_AUTO_RETRY')) {
                        return false;
                    }
                    if (attempt >= retries) {
                        logError('retryOn error run out of retries', { error, attempt, retries, response, body });
                        return false;
                    }
                    logDebug('retrying on 5xx error', { error, attempt, retries, response, body });
                    return true;
                }
                logDebug('retryOn returning false as error was not a 5xx error');
                return false;
            },
            retryDelay: (attempt) => {
                const retryDelay = 10 + 2 ** attempt * 1000; // 10 seconds is the minimum allowed retry time on the GK-API side
                logDebug('retryDelay', { attempt: attempt + 1, retryDelay });
                return retryDelay;
            },
        };
        this.fetchWithRetry = fetchBuilder(this.fetchImplementation, this.defaultRetryParams);
    }
    instanceName() {
        var _a, _b;
        return (_b = (_a = this.queryParams) === null || _a === void 0 ? void 0 : _a.gatekeeperNetworkAddress) === null || _b === void 0 ? void 0 : _b.substring(0, 6);
    }
    abort() {
        var _a, _b;
        logDebug(`abort ${(_b = (_a = this.queryParams) === null || _a === void 0 ? void 0 : _a.gatekeeperNetworkAddress) === null || _b === void 0 ? void 0 : _b.substring(0, 6)}`);
        this.abortController.abort();
    }
    addQueryParams(url) {
        if (!this.queryParams)
            return;
        Object.entries(this.queryParams).forEach(([key, value]) => {
            url.searchParams.append(key, value);
        });
    }
    continueIfNotAborted(fn) {
        logDebug(`continueIfNotAborted ${this.instanceName()} this.abortController.signal.aborted: ${this.abortController.signal.aborted}`, this.queryParams);
        if (!this.abortController.signal.aborted) {
            return fn();
        }
        return null;
    }
    urlForWallet(walletAddress) {
        const url = new URL(`${this.baseUrl}/${walletAddress}`);
        this.addQueryParams(url);
        return url.toString();
    }
    async getGatekeeperRecordWithPayload(walletAddress) {
        logDebug(`getGatekeeperRecordWithPayload: ${this.instanceName()}  this.abortController.signal.aborted: ${this.abortController.signal.aborted}`);
        return this.fetchWithRetry(this.urlForWallet(walletAddress), {
            method: 'GET',
            headers: this.headers,
            signal: this.abortController.signal,
        })
            .then(this.continueIfNotAborted(() => async (response) => ({
            state: GatekeeperAPIStatus[GatekeeperAPIStatus[response.status]],
            payload: await response.json(),
        })))
            .catch((error) => {
            if (error.name === 'AbortError') {
                logDebug('error due to abort controller signal aborted');
                return null;
            }
            throw error;
        });
    }
    async getGatekeeperStatus(walletAddress) {
        logDebug(`getGatekeeperStatus: ${this.instanceName()}  this.abortController.signal.aborted: ${this.abortController.signal.aborted}`);
        return this.fetchWithRetry(this.urlForWallet(walletAddress), {
            method: 'HEAD',
            headers: this.headers,
            signal: this.abortController.signal,
        })
            .then(this.continueIfNotAborted(() => ({ status }) => status))
            .catch((error) => {
            logError('getGatekeeperStatus', error);
            if (error.name === 'AbortError') {
                logDebug('error due to abort controller signal aborted');
                return GatekeeperAPIStatus.SERVER_FAILURE;
            }
            throw error;
        });
    }
    async requestGatewayTokenFromGatekeeper({ wallet, payload, proof, ownerSigns, }) {
        logDebug(`requestGatewayTokenFromGatekeeper: ${this.instanceName()}  this.abortController.signal.aborted: ${this.abortController.signal.aborted}`, Object.assign(Object.assign({}, payload), { proof }));
        // We only pass the wallet public key as part of the request if
        // it was not passed as part of the presentation.
        const body = Object.assign(Object.assign({}, payload), { proof, address: wallet.publicKey, ownerSigns });
        logDebug('requestGatewayTokenFromGatekeeper Requesting a new gatekeeper token...', body);
        const url = new URL(this.baseUrl);
        this.addQueryParams(url);
        return this.fetchWithRetry(url.toString(), {
            method: 'POST',
            headers: Object.assign(Object.assign({}, this.headers), { 'Content-Type': 'application/json' }),
            body: JSON.stringify(body),
            signal: this.abortController.signal,
        })
            .then(this.continueIfNotAborted(() => async (resp) => {
            const { status } = resp;
            const result = (await resp.json());
            return Object.assign(Object.assign({}, result), { status });
        }))
            .catch((error) => {
            logError('requestGatewayTokenFromGatekeeper', error);
            if (error.name === 'AbortError') {
                logDebug('error due to abort controller signal aborted');
            }
            return null;
        });
    }
    /**
     * Tries to refresh a token.
     * If it fails with a 5xx, handleFetchError will retry a number of times.
     */
    async refreshToken({ wallet, payload, proof, ownerSigns, }) {
        var _a, _b;
        logDebug(`refreshToken: ${(_b = (_a = this.queryParams) === null || _a === void 0 ? void 0 : _a.gatekeeperNetworkAddress) === null || _b === void 0 ? void 0 : _b.substring(0, 6)}  this.abortController.signal.aborted: ${this.abortController.signal.aborted}`, { payload });
        const body = Object.assign(Object.assign({}, payload), { proof, request: 'refresh', ownerSigns });
        return this.fetchWithRetry(this.urlForWallet(wallet.publicKey), {
            method: 'PATCH',
            headers: Object.assign(Object.assign({}, this.headers), { 'Content-Type': 'application/json' }),
            body: JSON.stringify(body),
            signal: this.abortController.signal,
        })
            .then(async (resp) => {
            const { status } = resp;
            const result = (await resp.json());
            return Object.assign(Object.assign({}, result), { status });
        })
            .catch((error) => {
            logError('refreshToken', error);
            if (error.name === 'AbortError') {
                logDebug('error due to abort controller signal aborted');
            }
            return null;
        });
    }
}
