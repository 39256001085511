/* eslint-disable @typescript-eslint/no-unused-vars */
import { GatekeeperAPIStatus, State } from '../types';
import { prefixLogger } from '../logger';
import { pollUntilConditionMet } from './utils';
const GatewayTokenActionCreatorImplementation = ({ walletAddress, chainImplementation, gatekeeperClient, dispatch, networkConfig, ownerSigns, }) => {
    const logDebug = prefixLogger('useChain').debug;
    const logError = prefixLogger('useChain').error;
    const isTokenCreated = (code) => code === GatekeeperAPIStatus.ISSUED;
    const isTokenPending = (code) => code === GatekeeperAPIStatus.REQUESTED;
    const isRequestedRetriesExhausted = (code) => code === GatekeeperAPIStatus.REQUESTED_RETRIES_EXHAUSTED;
    const isFailure = (code) => !isTokenPending(code) && !isRequestedRetriesExhausted(code) && code >= 400;
    const pollForActiveOnChainToken = async (pollChainNumberRetries = networkConfig.pollChainNumberRetries) => {
        if (!chainImplementation) {
            return Promise.reject();
        }
        return pollUntilConditionMet(chainImplementation.findGatewayToken, (onChainToken) => {
            if (!onChainToken)
                return false; // keep polling
            if (!onChainToken.expiryTime)
                return true;
            if (onChainToken.state === State.ACTIVE)
                return true;
            throw new Error('Token found but not ACTIVE');
        }, networkConfig.pollChainIntervalMilliseconds, pollChainNumberRetries);
    };
    const waitForGatewayToken = async ({ abortController, pollChainNumberRetries = networkConfig.pollChainNumberRetries } = {
        pollChainNumberRetries: networkConfig.pollChainNumberRetries,
    }) => {
        // poll the blockchain until we have a status for a created record
        // if ownerSigns is false, then poll the gatekeeper for a active tokenChange
        // if we don't get a created token, then we consider it a failure
        try {
            const token = await pollForActiveOnChainToken(pollChainNumberRetries);
            if (abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted) {
                return;
            }
            logDebug('Result from pollForActiveOnChainToken', token);
            if (!token) {
                logError('Token not found onChain');
                throw new Error('Token not found onChain');
            }
            dispatch({ type: 'tokenChange', token });
            dispatch({ type: 'civicPass_check_token_status', token });
        }
        catch (error) {
            logError('Error polling for active token on chain', error);
            // If owner signs is true then fail the flow without checking the gatekeeper
            if (ownerSigns) {
                logError('Failed to find Gateway token on-chain with ownerSigns:true, failing');
                dispatch({ type: 'civicPass_owner_transaction_timeout' });
                return;
            }
            if (!gatekeeperClient) {
                logError('no gatekeeper client, returning');
                return;
            }
            logError('Failed to find Gateway token on-chain with ownerSigns:false, checking with gatekeeper');
            const gkApiStatus = await gatekeeperClient.getGatekeeperStatus(walletAddress);
            if (abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted) {
                return;
            }
            if (isRequestedRetriesExhausted(gkApiStatus)) {
                logError('Retried exhausted', { gkApiStatus });
                dispatch({ type: 'civicPass_requested_retries_exhausted' });
                return;
            }
            // if the token is still pending or in review then keep polling on-chain
            if (isTokenPending(gkApiStatus)) {
                // we don't want to wait the whole amount of time if we've got to here, so just 4 retries = 3 x 2 seconds, a further
                // 6 seconds before we will ask the GK API for status again
                await waitForGatewayToken({ pollChainNumberRetries: 3, abortController });
                if (abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted) {
                    return;
                }
            }
            // retries have been exhausted and we still don't have a token
            // or the gatekeeper threw an error during issuance attempt
            if (isTokenCreated(gkApiStatus) || isFailure(gkApiStatus)) {
                logError('Failed to find Gateway token with gatekeeper status code', GatekeeperAPIStatus[gkApiStatus]);
                dispatch({ type: 'tokenNotFoundError' });
                dispatch({ type: 'civicPass_issuance_failure' });
            }
        }
    };
    return {
        waitForGatewayToken,
    };
};
export { GatewayTokenActionCreatorImplementation };
