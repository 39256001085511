import { useCallback, useRef } from 'react';
import * as R from 'ramda';
import { prefixLogger } from '../logger';
import { GatekeeperAPIStatus } from '../types';
import { POLL_UNTIL_NOT_REQUESTED_INTERVAL } from '../config';
const logDebug = prefixLogger('useGatekeeper').debug;
const logError = prefixLogger('useGatekeeper').error;
const useGatekeeper = ({ wallet, stage, gatekeeperClient, }, state, dispatch) => {
    const { ownerSigns } = state;
    /**
     * if a request is not already in progress, initiate a request to the gatekeeper for a new token
     * and dispatch an event so we know it's in progress
     */
    const waitForGatekeeperIssuanceRequest = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async ({ payload, proof, abortController, }) => {
        if (!wallet || !wallet.publicKey || !gatekeeperClient) {
            return;
        }
        logDebug('waitForGatekeeperIssuanceRequest ready to call requestGatewayTokenFromGatekeeper', {
            payload,
        });
        dispatch({ type: 'requestGatekeeperIssuance' });
        const requestGatewayTokenFromGatekeeperResult = await gatekeeperClient.requestGatewayTokenFromGatekeeper({
            wallet,
            payload,
            proof,
            ownerSigns: ownerSigns !== null && ownerSigns !== void 0 ? ownerSigns : false,
        });
        logDebug('requestGatewayTokenFromGatekeeperResult', {
            requestGatewayTokenFromGatekeeperResult,
            isAborted: abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted,
        });
        if (abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted) {
            return;
        }
        if (!requestGatewayTokenFromGatekeeperResult || requestGatewayTokenFromGatekeeperResult.status >= 500) {
            logError('Error requesting token from gatekeeper');
            dispatch({ type: 'requestGatekeeperIssuanceFailed' });
            dispatch({ type: 'civicPass_issuance_failure' });
            return;
        }
        if (requestGatewayTokenFromGatekeeperResult.status >= 400) {
            logError('Token rejected by gatekeeper');
            dispatch({ type: 'requestGatekeeperIssuanceRejected' });
            dispatch({
                type: 'civicPass_issuance_rejected',
                payload: R.pick(['errorCode', 'payload'], requestGatewayTokenFromGatekeeperResult),
            });
            return;
        }
        const { pending } = requestGatewayTokenFromGatekeeperResult;
        if (pending) {
            dispatch({ type: 'requestGatekeeperIssuanceInReview', pending });
            return;
        }
        const { transaction } = requestGatewayTokenFromGatekeeperResult;
        logDebug('Successfully requested gatekeeper token, setting gatewayTokenTransaction and dispatching requestGatekeeperIssuanceComplete', { transaction });
        dispatch({ type: 'requestGatekeeperIssuanceComplete', gatewayTokenTransaction: transaction });
    }, [gatekeeperClient, wallet, stage, ownerSigns]);
    /**
     * Using useRef allows us to store a promise that can be resolved
     * whenever we have the powo we need to return
     */
    const pollingTimer = useRef();
    const pollGatekeeperRef = useRef();
    const pollUntilNotRequested = useCallback((abortController) => {
        logDebug('pollUntilNotRequested');
        pollGatekeeperRef.current = new Promise((resolve, reject) => {
            try {
                if ((wallet === null || wallet === void 0 ? void 0 : wallet.publicKey) && gatekeeperClient) {
                    pollingTimer.current = setInterval(async () => {
                        const record = await gatekeeperClient.getGatekeeperRecordWithPayload(wallet.publicKey);
                        logDebug('pollUntilNotRequested record', {
                            record,
                            aborted: abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted,
                            POLL_UNTIL_NOT_REQUESTED_INTERVAL,
                        });
                        if (abortController === null || abortController === void 0 ? void 0 : abortController.signal.aborted) {
                            return;
                        }
                        if (!record || !(record === null || record === void 0 ? void 0 : record.state) || (record === null || record === void 0 ? void 0 : record.state) === GatekeeperAPIStatus.SERVER_FAILURE) {
                            reject(new Error('Error polling gatekeeper, no state returned'));
                            return;
                        }
                        logDebug('pollUntilNotRequested record.state', record.state);
                        if (record.state !== GatekeeperAPIStatus.REQUESTED) {
                            logDebug('pollUntilNotRequested record.state record.state !== GatekeeperAPIStatus.REQUESTED, resolving...');
                            clearInterval(pollingTimer.current);
                            resolve(record);
                        }
                    }, POLL_UNTIL_NOT_REQUESTED_INTERVAL);
                }
            }
            catch (error) {
                logError('pollUntilNotRequested error', error);
                clearInterval(pollingTimer.current);
                reject(error);
            }
        });
        return pollGatekeeperRef.current;
    }, [JSON.stringify((gatekeeperClient === null || gatekeeperClient === void 0 ? void 0 : gatekeeperClient.initConfig) || {}), wallet === null || wallet === void 0 ? void 0 : wallet.publicKey]);
    return {
        waitForGatekeeperIssuanceRequest,
        gatekeeperClient,
        pollUntilNotRequested,
    };
};
export default useGatekeeper;
