import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { statusFromToken } from '../useReducer/utils';
import { prefixLogger } from '../logger';
import { ExtendedGatewayStatus, GatewayStatus, RefreshTokenState } from '../types';
import { getTokenRefreshIntervalMilliseconds, hasExpired, isTokenRefreshRequired } from '../utils/tokenUtils';
import { AlreadyRefreshedError } from '../errors';
const logDebug = prefixLogger('useRefresh').debug;
const logWarn = prefixLogger('useRefresh').warn;
export const reducer = (state, action) => {
    logDebug('useRefresh reducer: action', action.type);
    switch (action.type) {
        case 'refresh_start': {
            logDebug(`useRefresh refresh_start: gatewayStatus: GatewayStatus.REFRESH_TOKEN_REQUIRED`);
            return Object.assign(Object.assign({}, state), { walletPowoInProgress: false, powoFinished: false, powoRequested: undefined, refreshInProgress: true, gatewayStatus: GatewayStatus.REFRESH_TOKEN_REQUIRED });
        }
        case 'refresh_in_progress': {
            return Object.assign(Object.assign({}, state), { refreshTokenState: RefreshTokenState.IN_PROGRESS });
        }
        case 'refresh_complete': {
            return Object.assign(Object.assign({}, state), { refreshTokenState: undefined, gatewayStatus: statusFromToken(state, state.gatewayToken), refreshTimeoutId: undefined, civicPass: Object.assign(Object.assign({}, state.civicPass), { responsePayload: undefined }) });
        }
        case 'refresh_with_powo_in_progress': {
            return Object.assign(Object.assign({}, state), { gatewayStatus: ExtendedGatewayStatus.CONFIRM_OWNER_TRANSACTION });
        }
        case 'refresh_clear_timeout':
            return Object.assign(Object.assign({}, state), { refreshTokenState: undefined, refreshTimeoutId: undefined });
        case 'refresh_set_timeout':
            return Object.assign(Object.assign({}, state), { refreshTimeoutId: action.refreshTimeoutId, refreshTokenState: undefined });
        case 'refresh_token_success':
            return Object.assign(Object.assign({}, state), { refreshTokenState: RefreshTokenState.WAIT_FOR_ON_CHAIN, gatewayStatus: ExtendedGatewayStatus.TOKEN_REFRESH_IN_REVIEW });
        default:
            return state;
    }
};
const useRefresh = ({ stage, gatekeeperClient, networkConfig, chainConfig, }, state, dispatch) => {
    const { gatewayToken, gatekeeperNetworkAddress, ownerSigns, walletAddress, refreshTokenState, gatewayStatus } = state;
    const waitForUnexpiredTimeout = useRef(null);
    const refreshTimeout = useRef(null);
    const tokenExpirationMarginSeconds = useMemo(() => state.inputExpiryMarginSeconds || networkConfig.tokenExpirationMarginSeconds || 0, [state.inputExpiryMarginSeconds, networkConfig.tokenExpirationMarginSeconds]);
    /**
     * Start a timeout based on the expiration of the GatewayToken that will check if a token needs to be refreshed
     * event, triggering the refreshFlow
     */
    const checkForRefreshWithTimeout = useCallback(() => {
        logDebug('checkForRefreshWithTimeout: Checking if refresh required', {
            gatewayToken,
            refreshTimeout: refreshTimeout.current,
        });
        // only start the refresh timeout flow if one is not already in progress
        if (!refreshTimeout.current && (gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime)) {
            const tokenIsExpired = isTokenRefreshRequired({
                gatewayToken,
                tokenExpirationMarginSeconds,
            });
            // check if the token has already expired and is not in a refresh flow
            const refreshRequiredBeforeTimeout = tokenIsExpired &&
                (!refreshTokenState ||
                    (refreshTokenState &&
                        ![
                            RefreshTokenState.COMPLETED,
                            RefreshTokenState.IN_PROGRESS,
                            RefreshTokenState.WAIT_FOR_ON_CHAIN,
                            RefreshTokenState.REQUIRES_POWO,
                        ].includes(refreshTokenState)));
            const checkForExpirationIntervalMilliseconds = getTokenRefreshIntervalMilliseconds(gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime, tokenExpirationMarginSeconds);
            logDebug('checkForRefreshWithTimeout business logic parameters', {
                tokenExpirationMarginSeconds,
                tokenIsExpired,
                checkForExpirationIntervalMilliseconds,
                refreshTokenState: refreshTokenState && RefreshTokenState[refreshTokenState],
                refreshRequiredBeforeTimeout,
                gatewayStatus: gatewayStatus && GatewayStatus[gatewayStatus],
                gatewayStatusFromToken: GatewayStatus[statusFromToken(state, gatewayToken)],
            });
            // if the token has already expired and refresh isn't in progress, then dispatch a civicPass state to show the iframe
            // and allow the user to start the refresh flow
            if (refreshRequiredBeforeTimeout) {
                logDebug('checkForRefreshWithTimeout token is expired and refresh flow needs to be triggered', {
                    gatewayStatus: gatewayStatus && GatewayStatus[gatewayStatus],
                    tokenIsExpired,
                });
                if (gatewayStatus !== statusFromToken(state, gatewayToken)) {
                    dispatch({ type: 'refresh_complete' });
                    dispatch({ type: 'civicPass_check_token_status', token: gatewayToken });
                }
                return;
            }
            // the token is expired but is currently in a refresh flow, so in this case do nothing
            if (gatewayToken && tokenIsExpired && refreshTokenState === RefreshTokenState.WAIT_FOR_ON_CHAIN) {
                logDebug('checkForRefreshWithTimeout token is expired and waiting for confirmation on chain, check the on-chain status', {
                    gatewayStatus: gatewayStatus && GatewayStatus[gatewayStatus],
                    tokenIsExpired,
                });
                dispatch({ type: 'refresh_complete' });
                dispatch({ type: 'civicPass_check_token_status', token: gatewayToken });
                return;
            }
            logDebug('checkForRefreshWithTimeout the token is not expired, start a timeout that will ask the user to refresh upon completion');
            // the token isn't expired, so start a refresh check flow that will
            dispatch({ type: 'refresh_in_progress' });
            // check if refresh is complete, i.e. the token isn't expired any more and this hasn't already been handled
            if (!tokenIsExpired && refreshTokenState) {
                logDebug('refreshTokenState exists, dispatching refresh_complete');
                refreshTimeout.current = null;
                dispatch({ type: 'refresh_complete' });
            }
            // create a new timeout: when finished, this will dispatch the refresh_set_timeout event will trigger the useEffect below which
            // will go through the complete refresh business logic
            if (checkForExpirationIntervalMilliseconds) {
                refreshTimeout.current = setTimeout(() => {
                    logDebug('checkForRefreshWithTimeout timeout expired, dispatching refresh_set_timeout checkForExpirationIntervalMilliseconds', checkForExpirationIntervalMilliseconds);
                    refreshTimeout.current = null;
                    dispatch({ type: 'refresh_complete' });
                    checkForRefreshWithTimeout();
                }, checkForExpirationIntervalMilliseconds); // this will be cleared on completion
                // add refresh timeout to state so that it can be cleared on disconnect if requred
                logDebug('checkForRefreshWithTimeout setting token refresh timeout, dispatching refresh_set_timeout', {
                    expiring: gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime,
                    checkForExpirationIntervalMilliseconds,
                    margin: tokenExpirationMarginSeconds,
                });
                dispatch({
                    type: 'refresh_set_timeout',
                    refreshTimeoutId: refreshTimeout.current,
                });
            }
        }
    }, [
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.state,
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.owner,
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime,
        stage,
        tokenExpirationMarginSeconds,
        refreshTokenState,
        gatewayStatus,
        state,
        refreshTimeout,
    ]);
    /**
     * handle when the refresh token timeout is expired by restarting the process
     */
    useEffect(() => {
        logDebug('useEffect handle when the refresh token timeout is expired by restarting the process');
        if (gatewayToken) {
            refreshTimeout.current = null;
            checkForRefreshWithTimeout();
        }
    }, [gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.state, gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.owner, gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime]);
    /**
     * use the passed proof of wallet ownership string to call the gatekeeper refresh token
     * endpoint.
     * On server error (5xx), retry with backoff.
     * On all other errors, e.g. 400, move to a REFRESH_FAILED state.
     */
    const refreshToken = useCallback((useWallet) => async ({ proof, payload }) => {
        if (!gatekeeperClient) {
            return Promise.reject();
        }
        logDebug('Calling gatekeeper api with payload and (optional) proof', { payload, proof });
        if (proof) {
            dispatch({ type: 'refresh_with_powo_in_progress' });
        }
        const refreshResult = await gatekeeperClient.refreshToken({
            wallet: useWallet,
            payload,
            proof,
            ownerSigns: ownerSigns !== null && ownerSigns !== void 0 ? ownerSigns : false,
        });
        // fail for 4XX errors
        if (!refreshResult || (refreshResult.status >= 400 && refreshResult.status < 500)) {
            throw new Error(`Error ${refreshResult === null || refreshResult === void 0 ? void 0 : refreshResult.status} from refresh gatekeeper token request`);
        }
        // Fail if client sends and no transaction is returned
        if (ownerSigns && (R.isNil(refreshResult === null || refreshResult === void 0 ? void 0 : refreshResult.transaction) || R.isEmpty(refreshResult === null || refreshResult === void 0 ? void 0 : refreshResult.transaction))) {
            // the GK API didn't return a transaction because the token expiration is within the expected tolerance period
            // in this case we throw an error to be handled upstream
            if (refreshResult.status === 200) {
                throw new AlreadyRefreshedError('Token already refreshed');
            }
            throw new Error(`Error ${refreshResult.status} no transaction returned from owner signs gatekeeper refresh request`);
        }
        return refreshResult.transaction;
    }, [
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.state,
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.owner,
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime,
        gatekeeperClient === null || gatekeeperClient === void 0 ? void 0 : gatekeeperClient.initConfig,
        checkForRefreshWithTimeout,
        ownerSigns,
        walletAddress,
    ]);
    /**
     * wait until a gateway token exists in state before resolving the promise
     */
    const waitForUnexpiredGatewayToken = useCallback(() => {
        return new Promise((resolve, reject) => {
            const isExpired = (gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime) && hasExpired(gatewayToken.expiryTime);
            logDebug('waitForUnexpiredGatewayToken: Waiting for unexpired token', {
                gatewayToken,
                currentRefreshTimeoutId: waitForUnexpiredTimeout.current,
                isExpired,
                waitForTokenRefreshTimoutMilliseconds: chainConfig.waitForTokenRefreshTimoutMilliseconds,
            });
            if (waitForUnexpiredTimeout.current) {
                logDebug('waitForUnexpiredGatewayToken: Clearing interval for gatewayToken', waitForUnexpiredTimeout.current);
                clearTimeout(waitForUnexpiredTimeout.current);
                waitForUnexpiredTimeout.current = null;
            }
            if (!isExpired) {
                logDebug('waitForUnexpiredGatewayToken: Gateway token is unexpired', gatewayToken);
                resolve();
                return;
            }
            logDebug('waitForUnexpiredGatewayToken creating timeout at: ', new Date());
            waitForUnexpiredTimeout.current = setTimeout(() => {
                logWarn('setTimeoutForRefresh timeout reached at ', new Date());
                reject(new Error('Gateway token refresh has not been updated onChain'));
            }, chainConfig.waitForTokenRefreshTimoutMilliseconds);
            logDebug('waitForUnexpiredGatewayToken: Starting check for Gateway token expiration timeout with identifier', waitForUnexpiredTimeout.current);
            if (ownerSigns) {
                logDebug('waitForUnexpiredGatewayToken: emitting awaiting owner transaction');
                // show the awaiting owner transaction UI if this is ownersigns
                dispatch({ type: 'civicPass_awaiting_owner_transaction' });
            }
        });
    }, [gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime, gatekeeperNetworkAddress]);
    return {
        checkForRefreshWithTimeout,
        refreshToken,
        waitForUnexpiredGatewayToken,
    };
};
export default useRefresh;
