/**
 * Prepare an object to be sent as URL params, which expects a string value for any object keys
 * any null or empty values should be removed
 * @param Record<string, unknown>
 * @returns Record<string, string>
 */
export const objectToURLParams = (obj) => obj
    ? Object.entries(obj).reduce((a, [k, v]) => {
        if (v === undefined ||
            v === null ||
            (v instanceof Array && v.length === 0) ||
            (v instanceof Object && Object.keys(v).length === 0) ||
            v === '') {
            return a;
        }
        return Object.assign(Object.assign({}, a), { [k]: v.toString() }); // Convert to string as needed
    }, {})
    : {};
