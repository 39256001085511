import { prefixLogger } from '../logger';
import config from '../networkConfig';
import { GatewayStatus, TokenIssuanceState, CivicPassIssuanceStatus, State, GatekeeperAPIStatus, ExtendedGatewayStatus, FetchStatus, ValidationStatus, } from '../types';
import { getFlowId } from '../utils/analytics';
import { isTokenRefreshRequired } from '../utils/tokenUtils';
const logDebug = prefixLogger('useUserInteraction').debug;
export const fetchInProgress = (state) => state.gatekeeperRecordState === GatekeeperAPIStatus.REQUESTING || state.fetchOnChainStatus === FetchStatus.FETCHING;
export const fetchNotStarted = (state) => state.gatekeeperRecordState === undefined || state.fetchOnChainStatus === undefined;
export const hasFetchError = (state) => state.gatekeeperRecordState === GatekeeperAPIStatus.SERVER_FAILURE || state.fetchOnChainStatus === FetchStatus.ERROR;
// eslint-disable-next-line import/prefer-default-export
export const resetState = (state) => {
    logDebug('resetting state...');
    return Object.assign(Object.assign({}, state), { dataCollectionStatus: undefined, fetchOnChainStatus: undefined, refreshTokenState: undefined, iframeSrcUrl: undefined, gatewayTokenTransaction: undefined, tokenCreatedOrChangedListenerId: undefined, tokenExpectedTimerId: undefined, partnerAppId: undefined, pending: undefined, gatewayStatus: GatewayStatus.UNKNOWN, tokenRequested: false, iframeMinimized: true, firstTokenCheck: true, renderIframe: false, gatewayToken: undefined, powoRequested: undefined, refreshTimeoutId: undefined, powoFinished: false, refreshInProgress: false, tokenIssuanceState: TokenIssuanceState.NOT_REQUESTED, walletToRefresh: undefined, walletPowoInProgress: false, ownerTransactionConfirmed: false, gatekeeperRecordState: undefined, civicPass: {
            status: CivicPassIssuanceStatus.NOT_REQUESTED,
        }, userInitiatedFlow: false, flowId: getFlowId('', {}), did: undefined });
};
const isLocationNotSupported = (recordState) => {
    if (!recordState)
        return false;
    return [GatekeeperAPIStatus.LOCATION_NOT_SUPPORTED].includes(recordState);
};
const isIssuedLocationNotSupported = (recordState) => {
    if (!recordState)
        return false;
    return [GatekeeperAPIStatus.ISSUED_LOCATION_NOT_SUPPORTED].includes(recordState);
};
const isIssuedVpnNotSupported = (recordState) => {
    if (!recordState)
        return false;
    return [GatekeeperAPIStatus.ISSUED_VPN_NOT_SUPPORTED].includes(recordState);
};
const isVpnNotSupported = (recordState) => {
    if (!recordState)
        return false;
    return [GatekeeperAPIStatus.VPN_NOT_SUPPORTED].includes(recordState);
};
const isRefreshTokenRequired = (recordState) => {
    if (!recordState)
        return false;
    return [GatekeeperAPIStatus.ISSUED_EXPIRED, GatekeeperAPIStatus.ISSUED_EXPIRY_APPROACHING].includes(recordState);
};
export const validationProcessToGatewayStatus = {
    [ValidationStatus.COLLECTING]: GatewayStatus.COLLECTING_USER_INFORMATION,
    [ValidationStatus.PROCESSING]: GatewayStatus.VALIDATING_USER_INFORMATION,
    [ValidationStatus.IN_REVIEW]: GatewayStatus.VALIDATING_USER_INFORMATION,
    [ValidationStatus.FAILED]: GatewayStatus.USER_INFORMATION_REJECTED,
    [ValidationStatus.NOT_FOUND]: GatewayStatus.NOT_REQUESTED,
    [ValidationStatus.COMPLETED]: GatewayStatus.USER_INFORMATION_VALIDATED,
};
const isInReview = (state) => [ExtendedGatewayStatus.TOKEN_IN_PARTNER_REVIEW, ExtendedGatewayStatus.TOKEN_REFRESH_IN_REVIEW].includes(state.gatewayStatus);
/**
 * get the status from the passed gateway token, if it exists
 * Take into account the gatekeeper record state, which overrides the gateway status if
 * it is set to a location-not-supported state
 * @param {RootState} state
 * @param {GatewayToken} gatewayToken
 * @returns {InternalGatewayStatus}
 */
export const statusFromToken = (state, gatewayToken) => {
    const networkConfig = config({
        gatekeeperNetworkAddress: state.gatekeeperNetworkAddress,
        stage: state.stage,
        chainType: state.chainType,
    });
    if (!gatewayToken) {
        return state.gatewayStatus;
    }
    const tokenExpirationMarginSeconds = (state === null || state === void 0 ? void 0 : state.inputExpiryMarginSeconds) || networkConfig.tokenExpirationMarginSeconds || 0;
    switch (gatewayToken.state) {
        case State.ACTIVE:
            if (isLocationNotSupported(state.gatekeeperRecordState)) {
                return GatewayStatus.LOCATION_NOT_SUPPORTED;
            }
            if (isVpnNotSupported(state.gatekeeperRecordState) || isIssuedVpnNotSupported(state.gatekeeperRecordState)) {
                return GatewayStatus.VPN_NOT_SUPPORTED;
            }
            if (isIssuedLocationNotSupported(state.gatekeeperRecordState)) {
                return GatewayStatus.REFRESH_TOKEN_REQUIRED;
            }
            if (state.gatewayStatus === GatewayStatus.PROOF_OF_WALLET_OWNERSHIP) {
                return GatewayStatus.PROOF_OF_WALLET_OWNERSHIP;
            }
            return isTokenRefreshRequired({
                gatewayToken,
                tokenExpirationMarginSeconds,
            })
                ? GatewayStatus.REFRESH_TOKEN_REQUIRED
                : GatewayStatus.ACTIVE;
        case State.REVOKED:
            return GatewayStatus.REVOKED;
        case State.FROZEN:
            return GatewayStatus.FROZEN;
        default:
            return GatewayStatus.UNKNOWN;
    }
};
/**
 * Returns a validated gateway status, taking into account:
 * - whether checks are in progress (return CHECKING)
 * - whether the gatekeeper record state is set to a location-not-supported state (return LOCATION_NOT_SUPPORTED)
 * - whether the gatekeeper record state is set to a vpn-not-supported state (return VPN_NOT_SUPPORTED)
 * - whether the gatekeeper record state is set to an issued location-not-supported state (return REFRESH_TOKEN_REQUIRED)
 * - whether a data collection status exists, and if so, whether it is a subset of NOT_REQUESTED (return the data collection status)
 * @param {InternalGatewayStatus} status
 * @param {RootState} state
 * @returns {RootState}
 */
export const gatewayStatusIfAllowed = (status, state) => {
    if (hasFetchError(state)) {
        // if there's already a gateway token in state, then this is the source of truth
        if (state.gatewayToken) {
            return statusFromToken(state, state.gatewayToken);
        }
        return GatewayStatus.ERROR;
    }
    // check for errors from the gatekeeper-api call: these should take precedence over all other statuses
    if (isLocationNotSupported(state.gatekeeperRecordState)) {
        return GatewayStatus.LOCATION_NOT_SUPPORTED;
    }
    if (isVpnNotSupported(state.gatekeeperRecordState) || isIssuedVpnNotSupported(state.gatekeeperRecordState)) {
        return GatewayStatus.VPN_NOT_SUPPORTED;
    }
    // if the location isn't supported but the user already has a token, then they need to refresh it
    if (isRefreshTokenRequired(state.gatekeeperRecordState) ||
        isIssuedLocationNotSupported(state.gatekeeperRecordState)) {
        return GatewayStatus.REFRESH_TOKEN_REQUIRED;
    }
    if (state.gatewayToken) {
        return statusFromToken(state, state.gatewayToken);
    }
    if (isInReview(state)) {
        return state.gatewayStatus;
    }
    if (state.gatekeeperRecordState === GatekeeperAPIStatus.ISSUED) {
        return GatewayStatus.ACTIVE;
    }
    // we can't make a definitive decision on the gateway status if checks are still in progress, leave it at CHECKING
    if (fetchInProgress(state)) {
        return GatewayStatus.CHECKING;
    }
    // if the requested status is NOT_REQUESTED, then check the data collection status, as data collection is a subset of NOT_REQUESTED
    if (status === GatewayStatus.NOT_REQUESTED && state.dataCollectionStatus) {
        return validationProcessToGatewayStatus[state.dataCollectionStatus];
    }
    return status;
};
