import { IFRAME_ID } from '../constants';
import { prefixLogger } from '../logger';
const logDebug = prefixLogger('RCRemoteSign').debug;
export const eventEmitter = (targetWindow) => {
    return {
        postMessage: (message, targetOrigin) => {
            targetWindow.postMessage(message, targetOrigin);
        },
    };
};
// TODO: Duplicate types in CivicSign
// Move to a central repo or use TS paths
export var CivicSignEventTypeResponse;
(function (CivicSignEventTypeResponse) {
    CivicSignEventTypeResponse["RESPONSE_PUBLIC_KEY"] = "RESPONSE_PUBLIC_KEY";
    CivicSignEventTypeResponse["RESPONSE_DID"] = "RESPONSE_DID";
    CivicSignEventTypeResponse["RESPONSE_SIGNED_PROOF"] = "RESPONSE_SIGNED_PROOF";
    CivicSignEventTypeResponse["RESPONSE_SIGNED_MESSAGE"] = "RESPONSE_SIGNED_MESSAGE";
})(CivicSignEventTypeResponse || (CivicSignEventTypeResponse = {}));
export var CivicSignEventTypeRequest;
(function (CivicSignEventTypeRequest) {
    CivicSignEventTypeRequest["REQUEST_PUBLIC_KEY"] = "REQUEST_PUBLIC_KEY";
    CivicSignEventTypeRequest["REQUEST_DID"] = "REQUEST_DID";
    CivicSignEventTypeRequest["REQUEST_SIGNED_PROOF"] = "REQUEST_SIGNED_PROOF";
    CivicSignEventTypeRequest["REQUEST_SIGNED_MESSAGE"] = "REQUEST_SIGNED_MESSAGE";
})(CivicSignEventTypeRequest || (CivicSignEventTypeRequest = {}));
export class RemoteSignWindowEventEmitter {
    constructor(targetWindow, instanceId) {
        this.instanceId = instanceId;
        this.targetWindow = targetWindow;
    }
    sendPublicKey(publicKey) {
        this.emit({
            event: CivicSignEventTypeResponse.RESPONSE_PUBLIC_KEY,
            data: publicKey,
        });
    }
    sendDid(did) {
        this.emit({
            event: CivicSignEventTypeResponse.RESPONSE_DID,
            data: did,
        });
    }
    sendSignedProof(proof, signatureMethod) {
        this.emit({
            event: CivicSignEventTypeResponse.RESPONSE_SIGNED_PROOF,
            data: { proof, signatureMethod },
        });
    }
    sendSignedMessage(message) {
        this.emit({
            event: CivicSignEventTypeResponse.RESPONSE_SIGNED_MESSAGE,
            data: message,
        });
    }
    emit(event) {
        this.targetWindow.postMessage(Object.assign(Object.assign({}, event), { instanceId: this.instanceId }), '*');
    }
}
export const remoteSignWindowEventEmitterImplementation = (instanceId, iframeBaseId = IFRAME_ID) => {
    var _a;
    const iFrameWindow = (_a = document.getElementById(`${iframeBaseId}_${instanceId}`)) === null || _a === void 0 ? void 0 : _a.contentWindow;
    if (!iFrameWindow) {
        logDebug(`remoteSignWindowEventEmitterImplementation: no iFrameWindow window found with instanceId: ${instanceId}`);
        return null;
    }
    return new RemoteSignWindowEventEmitter(eventEmitter(iFrameWindow), instanceId);
};
