import { prefixLogger } from '../logger';
import { GatewayStatus, ExtendedGatewayStatus } from '../types';
const logDebug = prefixLogger('utils').debug;
/** Map InternalGatewayStatuses that also contain non public API ExtendedGatewayStatuses to GatewayStatuses */
export const mapStatus = (internalStatus) => {
    switch (internalStatus) {
        // Map extended statuses to GatewayStatus
        case ExtendedGatewayStatus.AWAITING_OWNER_TRANSACTION:
            return GatewayStatus.IN_REVIEW;
        case ExtendedGatewayStatus.CONFIRM_OWNER_TRANSACTION:
            return GatewayStatus.IN_REVIEW;
        case ExtendedGatewayStatus.CHAIN_TIMEOUT_ERROR:
            return GatewayStatus.ERROR;
        case ExtendedGatewayStatus.CHAIN_TRANSACTION_ERROR:
            return GatewayStatus.ERROR;
        case ExtendedGatewayStatus.TOKEN_IN_PARTNER_REVIEW:
            return GatewayStatus.IN_REVIEW;
        case ExtendedGatewayStatus.TOKEN_REFRESH_IN_REVIEW:
            return GatewayStatus.IN_REVIEW;
        default:
            // otherwise return GatewayStatus
            return internalStatus;
    }
};
/** Return a display value for the given InternalGatewayStatus which includes both ExtendedGatewayStatuses and GatewayStatuses */
export const statusDisplayValue = (internalStatus) => {
    return ExtendedGatewayStatus[internalStatus] || GatewayStatus[internalStatus];
};
export const autoShowIframe = (state) => {
    var _a, _b;
    logDebug(`autoShowIframe state.options?.autoShowModal: ${(_a = state.options) === null || _a === void 0 ? void 0 : _a.autoShowModal} state.userInitiatedFlow: ${state.userInitiatedFlow}`);
    return ((_b = state.options) === null || _b === void 0 ? void 0 : _b.autoShowModal) && state.userInitiatedFlow;
};
