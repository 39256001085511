import { useCallback, useEffect } from 'react';
import { CivicSignEventTypeRequest, remoteSignWindowEventEmitterImplementation, } from '../utils/remoteSign';
import { prefixLogger } from '../logger';
const logDebug = prefixLogger('useCivicPassEventListener').debug;
const logError = prefixLogger('useCivicPassEventListener').error;
const useCivicPassEventListener = ({ wallet, chainImplementation, remoteSign, instanceId, iframeSrcUrl, dispatch, }) => {
    const dispatchSignMessageError = (error) => {
        logError('Error during signing', error);
        const errorMessage = error && error instanceof Error ? error.message : 'ChainTransaction error';
        const errorCode = error === null || error === void 0 ? void 0 : error.errorCode;
        logError('remoteSignerInst REQUEST_SIGNED_PROOF error', { errorCode, errorMessage });
        const payload = Object.assign({}, (errorCode ? { errorCode } : {}));
        logError('remoteSignerInst REQUEST_SIGNED_PROOF (error instanceof ChainError)', errorCode);
        dispatch({ type: 'civicPass_signMessageError', payload });
    };
    const getRemoteSign = () => remoteSign !== null && remoteSign !== void 0 ? remoteSign : remoteSignWindowEventEmitterImplementation(instanceId);
    const dispatchEvent = useCallback(async (response) => {
        if (!wallet || !chainImplementation)
            return;
        const events = {
            [CivicSignEventTypeRequest.REQUEST_PUBLIC_KEY]: () => {
                const remoteSignerInst = getRemoteSign();
                if (!remoteSignerInst) {
                    return Promise.resolve();
                }
                logDebug('REQUEST_PUBLIC_KEY dispatchEvent', {
                    remoteSignerInst,
                    wallet,
                    chainImplDid: chainImplementation.did,
                    chainImplInitProps: chainImplementation.initProps,
                });
                return new Promise((resolve) => {
                    remoteSignerInst === null || remoteSignerInst === void 0 ? void 0 : remoteSignerInst.sendPublicKey(wallet.publicKey);
                    resolve();
                });
            },
            [CivicSignEventTypeRequest.REQUEST_DID]: () => {
                const remoteSignerInst = getRemoteSign();
                if (!remoteSignerInst) {
                    return Promise.resolve();
                }
                logDebug('REQUEST_DID dispatchEvent', {
                    remoteSignerInst,
                    wallet,
                    chainImplDid: chainImplementation.did,
                    chainImplInitProps: chainImplementation.initProps,
                });
                return new Promise((resolve) => {
                    remoteSignerInst === null || remoteSignerInst === void 0 ? void 0 : remoteSignerInst.sendDid(chainImplementation.did);
                    resolve();
                });
            },
            [CivicSignEventTypeRequest.REQUEST_SIGNED_PROOF]: async () => {
                try {
                    const remoteSignerInst = getRemoteSign();
                    if (!remoteSignerInst) {
                        return await Promise.resolve();
                    }
                    logDebug('REQUEST_SIGNED_PROOF dispatchEvent', {
                        remoteSignerInst,
                        wallet,
                        chainImplDid: chainImplementation.did,
                        chainImplInitProps: chainImplementation.initProps,
                    });
                    const ProveWalletOwnershipResponse = await chainImplementation.proveWalletOwnership(response.payload);
                    remoteSignerInst === null || remoteSignerInst === void 0 ? void 0 : remoteSignerInst.sendSignedProof(ProveWalletOwnershipResponse.proof, ProveWalletOwnershipResponse.signatureMethod);
                }
                catch (error) {
                    dispatchSignMessageError(error);
                }
                return Promise.resolve();
            },
            [CivicSignEventTypeRequest.REQUEST_SIGNED_MESSAGE]: async () => {
                if (response.payload && chainImplementation.signMessage) {
                    const remoteSignerInst = getRemoteSign();
                    if (!remoteSignerInst) {
                        return Promise.resolve();
                    }
                    try {
                        const signedMessage = await chainImplementation.signMessage(response.payload);
                        remoteSignerInst === null || remoteSignerInst === void 0 ? void 0 : remoteSignerInst.sendSignedMessage(signedMessage);
                    }
                    catch (error) {
                        logError('Error during sign message', error);
                        dispatchSignMessageError(error);
                    }
                }
                return Promise.resolve();
            },
        };
        const event = events[response.request];
        if (event) {
            await event();
            logDebug('Successfully emitted compliance event', response);
        }
    }, [wallet === null || wallet === void 0 ? void 0 : wallet.publicKey, chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.initProps]);
    /**
     * Listen for post messages from the compliance iframe and dispatch events
     * based on the event type
     */
    useEffect(() => {
        if (!wallet || !chainImplementation) {
            return;
        }
        const controller = new AbortController();
        const handler = (response) => {
            var _a, _b;
            if (controller.signal.aborted) {
                logDebug('handler controller.signal.aborted returning');
                return;
            }
            if (iframeSrcUrl && response.origin !== new URL(iframeSrcUrl).origin) {
                return;
            }
            // If there are multiple instances of the component on the page, we only want to respond to messages using our own instanceId
            if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.instanceId) !== instanceId) {
                if ((_b = response.data) === null || _b === void 0 ? void 0 : _b.instanceId) {
                    logDebug('useEffect response.data?.id !== state.instanceId', { response, instanceId });
                }
                return;
            }
            dispatchEvent(response.data);
        };
        logDebug('Adding event listener for iframe civic-sign  post messages');
        window.addEventListener('message', handler);
        // eslint-disable-next-line consistent-return
        return () => {
            logDebug('Removing event listener for iframe civic-sign post messages');
            window.removeEventListener('message', handler);
            controller.abort();
        };
    }, [wallet, dispatchEvent, iframeSrcUrl]);
    return { dispatchEvent };
};
export default useCivicPassEventListener;
