var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useGateway } from '../gateway/GatewayContext';
import { getButtonText, getIcon, isDisabled } from './IdentityButton.utils';
const Button = styled.button `
  width: 180px;
  background-color: #282c34;
  outline: none;
  border: 1px solid #434343;
  height: 50px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
  &:active {
    top: 1px;
  }
  &:disabled:active {
    top: 0px;
  }
`;
const buttonTitleStyle = {
    color: 'white',
    fontSize: '16px',
    paddingLeft: '20px',
};
const buttonStyleLight = {
    background: '#fff',
    border: '1px solid #ccc',
};
const buttonTitleStyleLight = {
    color: '#666666',
};
export var ButtonMode;
(function (ButtonMode) {
    ButtonMode[ButtonMode["LIGHT"] = 0] = "LIGHT";
    ButtonMode[ButtonMode["DARK"] = 1] = "DARK";
})(ButtonMode || (ButtonMode = {}));
const IdentityButton = (_a) => {
    var { mode = ButtonMode.DARK } = _a, props = __rest(_a, ["mode"]);
    const { requestGatewayToken, gatewayStatus } = useGateway();
    return (_jsxs(Button, Object.assign({ className: props.className, onClick: requestGatewayToken, type: "button", disabled: isDisabled(gatewayStatus), style: mode === ButtonMode.LIGHT ? Object.assign({}, buttonStyleLight) : {} }, { children: [getIcon(gatewayStatus), _jsx("span", Object.assign({ "data-testid": "CIVIC_IDENTITY_BUTTON", style: mode === ButtonMode.LIGHT ? Object.assign(Object.assign({}, buttonTitleStyle), buttonTitleStyleLight) : buttonTitleStyle }, { children: getButtonText(gatewayStatus) }))] })));
};
export default IdentityButton;
