import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SVGClose from './SVGClose';
import SVGLoading from './SVGLoading';
import { TESTID_IFRAME_CLOSE_BTN } from '../constants';
const containerStyle = {
    background: 'rgba(0,0,0,0.5)',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    minHeight: '26',
    overflow: 'hidden',
    /* flex position the iframe container */
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};
/* Critical that this container has no overflow scroll all scrolling will be handled by content inside the iframe */
const containerContentStyle = {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '526px',
    width: '100%',
    minHeight: '26',
    maxHeight: '100',
    background: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
    overflow: 'hidden',
};
const containerButtonStyle = {
    top: '5px',
    right: '5px',
    padding: '10px',
    background: 'transparent',
    border: 'none',
    borderRadius: '25px',
    height: '50px',
    width: '50px',
    cursor: 'pointer',
    margin: '10px 0 0 0',
};
const containerButtonNoLogoStyle = Object.assign(Object.assign({}, containerButtonStyle), { margin: '0 0 0 0' });
const headerStyle = {
    height: '80px',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
    borderBottom: '1px solid #ddd',
    position: 'relative',
};
const logoStyle = {
    maxHeight: '40px',
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
};
const noLogoStyle = {
    height: '16px',
};
const DefaultWrapper = ({ children = null, onClose, logo, loaded = false, loading = false, }) => {
    const closeButtonStyle = logo ? containerButtonStyle : containerButtonNoLogoStyle;
    return (_jsx("div", Object.assign({ style: Object.assign(Object.assign({}, containerStyle), { position: 'fixed', flexDirection: 'column', zIndex: 999 }) }, { children: _jsxs("div", Object.assign({ style: Object.assign(Object.assign(Object.assign({}, containerContentStyle), { flexDirection: 'column', position: 'relative' }), (loading ? {} : { filter: 'contrast(0.5)' })) }, { children: [logo && loaded && (_jsx("header", Object.assign({ style: Object.assign(Object.assign({}, headerStyle), { position: 'relative' }) }, { children: _jsx("img", { style: logoStyle, src: logo, alt: "logo" }) }))), !logo && loaded && _jsx("div", { style: noLogoStyle }), !loaded && _jsx(SVGLoading, {}), _jsx("button", Object.assign({ "data-testid": TESTID_IFRAME_CLOSE_BTN, type: "button", onClick: onClose, style: Object.assign(Object.assign({}, closeButtonStyle), { display: loaded ? 'block' : 'none', position: 'absolute' }) }, { children: _jsx(SVGClose, {}) })), children] })) })));
};
// eslint-disable-next-line import/prefer-default-export
export const Wrapper = ({ wrapper, onClose, children = null, logo, loaded = false, loading = false, }) => {
    if (wrapper) {
        const WrapperComp = wrapper;
        return (_jsxs(WrapperComp, { children: [loaded && (_jsx("button", Object.assign({ type: "button", onClick: onClose, style: Object.assign(Object.assign({}, containerButtonStyle), { display: loaded ? 'block' : 'none', position: 'absolute' }) }, { children: _jsx(SVGClose, {}) }))), children] }));
    }
    return (_jsx(DefaultWrapper, Object.assign({ onClose: onClose, logo: logo, loaded: loaded, loading: loading }, { children: children })));
};
