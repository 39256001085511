import * as R from 'ramda';
import { ChainType } from './types';
var Network;
(function (Network) {
    Network["IGNITE"] = "ignitePass";
    Network["IGNITE_NFT"] = "igniteNftPass";
    Network["NILE"] = "nilePass";
    Network["BLUE_NILE"] = "blueNilePass";
    Network["GENESIS"] = "genesisPass";
    Network["BLUE_GENESIS"] = "blueGenesisPass";
    Network["UNIQUENESS"] = "uniquenessPass";
    Network["VAAL_PASS"] = "vaalPass";
})(Network || (Network = {}));
const defaultConfiguration = {
    networkAddressMappings: {
        // test networks
        tigoYhp9SpCDoCQmXGj2im5xa3mnjR1zuXrpCJ5ZRmi: Network.IGNITE,
        tig2iQjtihM8GbWS1z5LJi9ytTkj4poeh2N3MW4n9gt: Network.IGNITE_NFT,
        tniC2HX5yg2yDjMQEcUo1bHa44x9YdZVSqyKox21SDz: Network.NILE,
        tbniJdS9j7BWhUoJesjpqutC54AYr96cn2No7dJcqce: Network.BLUE_NILE,
        tgaxdij8CgAbfDDkhtkvZgEtwLPrVEXTQe3L4zkA7gE: Network.GENESIS,
        tb1g4GgywqGprgbuRw8RdDuPGUPC4CFMmmNFXfBX79J: Network.BLUE_GENESIS,
        tunQheuPpHhjjsbrUDp4rikqYez9UXv4SXLRHf9Kzsv: Network.UNIQUENESS,
        tvaaHL9BSgZGLRAqUrx1Fzs2Uneb6BWGdnYuqrFoXm3: Network.VAAL_PASS,
        // production networks
        ignREusXmGrscGNUesoU9mxfds9AiYTezUKex2PsZV6: Network.IGNITE,
        ign2PJfwxvYxAZpMdXgLdY4VLCnChPZWjtTeQwQfQdc: Network.IGNITE_NFT,
        ni1jXzPTq1yTqo67tUmVgnp22b1qGAAZCtPmHtskqYG: Network.NILE,
        bni1ewus6aMxTxBi5SAfzEmmXLf8KcVFRmTfproJuKw: Network.BLUE_NILE,
        gatbGF9DvLAw3kWyn1EmH5Nh1Sqp8sTukF7yaQpSc71: Network.GENESIS,
        b1gz9sD7TeH6cagodm4zTcAx6LkZ56Etisvgr6jGFQb: Network.BLUE_GENESIS,
        uniqobk8oGh4XBLMqM68K8M2zNu3CdYX7q5go7whQiv: Network.UNIQUENESS,
        vaa1QRNEBb1G2XjPohqGWnPsvxWnwwXF67pdjrhDSwM: Network.VAAL_PASS,
    },
    chainConfigs: {
        waitForTokenRefreshTimoutMilliseconds: 1000 * 30,
        [ChainType.ETHEREUM]: {
            waitForTokenRefreshTimoutMilliseconds: 1000 * 60 * 10, // 10 minutes
        },
    },
    networkConfigs: {
        // We need to check the Gatekeeper record to determine if the user passes all the gatekeeper network rules
        // This is not required for IgnitePass
        requiresGatekeeperRecordStatusCheck: true,
        tokenExpirationMarginSeconds: 0,
        pollChainIntervalMilliseconds: 5000,
        pollChainNumberRetries: 24,
        chainSpecificConfig: {
            [ChainType.SOLANA]: {
                pollChainIntervalMilliseconds: 5000,
                pollChainNumberRetries: 6, // 30 seconds
            },
        },
        [Network.GENESIS]: {
            tokenExpirationMarginSeconds: 60 * 60 * 6, // 6 hours
        },
        [Network.BLUE_GENESIS]: {
            tokenExpirationMarginSeconds: 60 * 60 * 6, // 6 hours
        },
        // these networks do not require a Gatekeeper record status check
        [Network.IGNITE]: {
            requiresGatekeeperRecordStatusCheck: false,
        },
        [Network.IGNITE_NFT]: {
            requiresGatekeeperRecordStatusCheck: false,
        },
    },
};
const testConfig = Object.assign(Object.assign({}, defaultConfiguration), { networkConfigs: Object.assign(Object.assign({}, defaultConfiguration.networkConfigs), { tokenExpirationMarginSeconds: 10 }) });
export const localConfig = Object.assign({}, defaultConfiguration);
const devConfig = Object.assign({}, defaultConfiguration);
const preprodConfig = Object.assign({}, defaultConfiguration);
const prodConfig = Object.assign({}, defaultConfiguration);
const stageConfigs = {
    test: testConfig,
    local: localConfig,
    dev: devConfig,
    preprod: preprodConfig,
    prod: prodConfig,
};
/**
 * Returns the network configuration, allowing for overrides per stage, GKN and chain type
 * @param options.gatekeeperNetworkAddress - The GKN to use for the network configuration
 * @param options.stage - The stage to use for the network configuration
 * @param options.chainType - The chain type to use for the network configuration
 * @returns {NetworkConfig}
 */
const config = ({ gatekeeperNetworkAddress, stage, chainType, }) => {
    var _a;
    const defaultNetworkConfig = defaultConfiguration.networkConfigs;
    const stageConfig = stageConfigs[stage];
    const networkName = gatekeeperNetworkAddress ? stageConfig === null || stageConfig === void 0 ? void 0 : stageConfig.networkAddressMappings[gatekeeperNetworkAddress] : null;
    const stageNetworkConfig = R.mergeDeepRight(stageConfig.networkConfigs, (stageConfig === null || stageConfig === void 0 ? void 0 : stageConfig.networkConfigs[networkName]) || {});
    const chainSpecificNetworkConfig = (chainType && ((_a = stageNetworkConfig.chainSpecificConfig) === null || _a === void 0 ? void 0 : _a[chainType])) || {};
    const networkConfig = R.mergeDeepRight(defaultNetworkConfig, R.mergeDeepRight(stageNetworkConfig, chainSpecificNetworkConfig));
    return networkConfig;
};
export const chainConfig = ({ stage, chainType }) => {
    var _a;
    const stageConfig = stageConfigs[stage];
    const chainConfiguration = chainType ? stageConfig === null || stageConfig === void 0 ? void 0 : stageConfig.chainConfigs[chainType] : stageConfig;
    const waitForTokenRefreshTimoutMilliseconds = (_a = chainConfiguration === null || chainConfiguration === void 0 ? void 0 : chainConfiguration.waitForTokenRefreshTimoutMilliseconds) !== null && _a !== void 0 ? _a : stageConfig.chainConfigs.waitForTokenRefreshTimoutMilliseconds;
    return {
        waitForTokenRefreshTimoutMilliseconds,
    };
};
export default config;
