import { findGatewayToken, onGatewayTokenChange, onGatewayToken, removeAccountChangeListener, } from '@identity.com/solana-gateway-ts';
import { PublicKey, SendTransactionError } from '@solana/web3.js';
import { create, proveTransaction } from '@identity.com/prove-solana-wallet';
import { ChainType, State, useGateway, ChainError, SignatureMethod, ErrorCode, prefixLogger, } from '@civic/common-gateway-react';
import { getGatekeeperEndpoint, makeConfig } from './config';
import { VERSION } from './version';
import { createSolanaTransactionFromBase64, parseTransactionErrorCode } from './util';
const logDebug = prefixLogger('SolanaChainImplementation').debug;
const logWarn = prefixLogger('SolanaChainImplementation').warn;
const isUnsupportedOperationError = (error) => { var _a; return error.code === -32603 || ((_a = error.message) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('unsupportedoperation')); };
const createSignMessageProofIfSupported = async (proveTransactionFn, proveSignMessageFn) => {
    try {
        if (!proveSignMessageFn) {
            logDebug('createSignMessageProofIfSupported no signMessageFn passed, using proveTransactionFn...');
            const proof = await proveTransactionFn();
            logDebug('createSignMessageProofIfSupported proveTransactionFn successful', {
                proof,
                signatureMethod: SignatureMethod.TRANSACTION,
            });
            return { proof, signatureMethod: SignatureMethod.TRANSACTION };
        }
        logDebug('createSignMessageProofIfSupported signMessageFn exists, calling proveSignMessageFn...');
        const proof = await proveSignMessageFn();
        logDebug('createSignMessageProofIfSupported proveSignMessageFn successful', {
            proof,
            signatureMethod: SignatureMethod.MESSAGE,
        });
        return { proof, signatureMethod: SignatureMethod.MESSAGE };
    }
    catch (error) {
        const useError = error;
        logWarn('createSignMessageProofIfSupported error', { error, useError });
        if (isUnsupportedOperationError(useError)) {
            logDebug('error is an unsupported operation error, trying proveTransaction');
            const proof = await proveTransactionFn();
            logDebug('createSignMessageProofIfSupported proveTransactionFn successful', {
                proof,
                signatureMethod: SignatureMethod.TRANSACTION,
            });
            return { proof, signatureMethod: SignatureMethod.TRANSACTION };
        }
        throw error;
    }
};
const listeners = [];
export const chainImplementation = ({ connection, cluster, publicKey, signTransaction, signMessage, handleTransaction, gatekeeperNetworkAddress, stage, }) => {
    logDebug('Connecting to cluster with commitment recent', connection === null || connection === void 0 ? void 0 : connection.rpcEndpoint);
    const requiresSignature = (transaction) => {
        return transaction.signatures.find((sig) => sig.publicKey.equals(publicKey)) !== undefined;
    };
    const removeGatewayAccountTokenChangeListener = (listenerId) => {
        logDebug(`removeGatewayAccountTokenChangeListener listenerId: ${listenerId} calling removeAccountChangeListener`);
        listeners[listeners.findIndex((value) => value === listenerId)] = undefined;
        removeAccountChangeListener(connection, listenerId);
    };
    const submitTransaction = async (transaction) => {
        const txSig = await connection.sendRawTransaction(transaction.serialize());
        // override connection commitment as we will poll/listen for confirmation of the token being created/refreshed
        const blockhash = await connection.getLatestBlockhash('processed');
        await connection.confirmTransaction(Object.assign(Object.assign({}, blockhash), { signature: txSig }), 'processed');
        return txSig;
    };
    logDebug('initProps', {
        passedInitProps: {
            network: cluster,
            owner: publicKey.toBase58(),
            gatekeeperNetwork: gatekeeperNetworkAddress.toBase58(),
            stage,
        },
    });
    return {
        initProps: {
            network: cluster,
            owner: publicKey.toBase58(),
            gatekeeperNetwork: gatekeeperNetworkAddress.toBase58(),
            stage,
        },
        dAppHandlesTransactions: !!handleTransaction,
        addOnGatewayTokenChangeListener: async (gatewayToken, tokenDidChange) => {
            const newListener = await Promise.resolve(onGatewayTokenChange(connection, new PublicKey(gatewayToken.identifier), (token) => {
                tokenDidChange({
                    issuingGatekeeper: token.issuingGatekeeper.toBase58(),
                    gatekeeperNetworkAddress: token.gatekeeperNetwork.toBase58(),
                    owner: token.owner.toBase58(),
                    state: State[token.state],
                    identifier: token.publicKey.toBase58(),
                    expiryTime: token.expiryTime,
                });
            }));
            logDebug(`addOnGatewayTokenChangeListener listeners.push(${newListener})`);
            listeners.push(newListener);
            return newListener;
        },
        addOnGatewayTokenCreatedOrChangedListener: (tokenCreatedOrChanged) => {
            const newListener = onGatewayToken(connection, publicKey, gatekeeperNetworkAddress, (token) => {
                tokenCreatedOrChanged({
                    issuingGatekeeper: token.issuingGatekeeper.toBase58(),
                    gatekeeperNetworkAddress: token.gatekeeperNetwork.toBase58(),
                    owner: token.owner.toBase58(),
                    state: State[token.state],
                    identifier: token.publicKey.toBase58(),
                    expiryTime: token.expiryTime,
                });
            });
            logDebug(`addOnGatewayTokenCreatedOrChangedListener listeners.push(${newListener})`);
            listeners.push(newListener);
            return newListener;
        },
        removeOnGatewayTokenChangeListener: (listenerId) => {
            // we set the listener to undefined so as not to disturb the existing indexes
            removeGatewayAccountTokenChangeListener(listenerId);
        },
        onDestroy: () => {
            logDebug('onDestroy', listeners);
            listeners.forEach((listenerId) => listenerId !== undefined && removeGatewayAccountTokenChangeListener(listenerId));
        },
        findGatewayToken: async () => {
            const onChainToken = await findGatewayToken(connection, publicKey, new PublicKey(gatekeeperNetworkAddress));
            if (!onChainToken)
                return undefined;
            return {
                issuingGatekeeper: onChainToken.issuingGatekeeper.toBase58(),
                gatekeeperNetworkAddress: onChainToken.gatekeeperNetwork.toBase58(),
                owner: onChainToken.owner.toBase58(),
                state: State[onChainToken.state],
                identifier: onChainToken.publicKey.toBase58(),
                expiryTime: onChainToken.expiryTime,
            };
        },
        proveWalletOwnership: async (message) => {
            try {
                logDebug('proveWalletOwnership', { message });
                const proveSignMessageFn = signMessage && message
                    ? () => create((input) => signMessage(Buffer.from(input)), message)
                    : undefined;
                const proveTransactionFn = () => proveTransaction(publicKey, signTransaction, makeConfig(connection.rpcEndpoint, cluster)).then((proveTxResult) => proveTxResult.toString('base64'));
                const response = await createSignMessageProofIfSupported(proveTransactionFn, proveSignMessageFn);
                logDebug('proveWalletOwnership', response);
                return response;
            }
            catch (error) {
                const parsedError = error;
                const useErrorCode = parseTransactionErrorCode(parsedError) === ErrorCode.SIGN_TRANSACTION_ERROR
                    ? ErrorCode.POWO_ERROR
                    : undefined;
                logDebug('proveWalletOwnership', {
                    error,
                    errorMessage: parsedError.message,
                    parsedErrorCode: parseTransactionErrorCode(parsedError),
                    useErrorCode,
                });
                throw new ChainError(parsedError.message, useErrorCode);
            }
        },
        signMessage: async (message) => {
            if (signMessage) {
                return signMessage(message);
            }
            throw new Error('signMessage is not defined');
        },
        handleUserSignedTransaction: async (partiallySignedTx) => {
            logDebug('handleUserSignedTransaction, user-provided handleTransaction', !!handleTransaction);
            const transaction = createSolanaTransactionFromBase64(partiallySignedTx);
            if (handleTransaction) {
                logDebug('custom handleTransaction function present, calling now...');
                return handleTransaction(transaction).catch((error) => {
                    throw new ChainError(error.message, ErrorCode.CUSTOM_HANDLE_TRANSACTION_ERROR, prefixLogger('SolanaChainImplementation'));
                });
            }
            let transactionToSend = transaction;
            if (requiresSignature(transaction)) {
                transactionToSend = await signTransaction(transaction).catch((error) => {
                    throw new ChainError(error.message, ErrorCode.SIGN_TRANSACTION_ERROR, prefixLogger('SolanaChainImplementation'));
                });
            }
            return submitTransaction(transactionToSend).catch((error) => {
                const parsedError = error;
                if (parsedError instanceof SendTransactionError || parseTransactionErrorCode(parsedError)) {
                    logDebug('error is instance of SendTransactionError', error);
                    throw new ChainError(parsedError.message, parseTransactionErrorCode(parsedError), prefixLogger('SolanaChainImplementation'));
                }
                throw new ChainError(parsedError.message, ErrorCode.SEND_TRANSACTION_ERROR, prefixLogger('SolanaChainImplementation'));
            });
        },
        did: `did:sol:${publicKey.toBase58()}`,
        chainType: ChainType.SOLANA,
        chainNetwork: cluster,
        httpConfig: {
            baseUrl: getGatekeeperEndpoint(stage),
            queryParams: { network: cluster },
            headers: { 'x-civic-client': VERSION },
        },
    };
};
export const useSolanaGateway = () => {
    const { gatewayToken, gatewayTokenTransaction } = useGateway();
    const solanaGatewayToken = gatewayToken
        ? {
            issuingGatekeeper: new PublicKey(gatewayToken.issuingGatekeeper),
            gatekeeperNetworkAddress: new PublicKey(gatewayToken.gatekeeperNetworkAddress),
            owner: new PublicKey(gatewayToken.owner),
            state: gatewayToken.state,
            publicKey: new PublicKey(gatewayToken.identifier),
            expiryTime: gatewayToken.expiryTime,
        }
        : undefined;
    const solanaGatewayTokenTransaction = gatewayTokenTransaction
        ? createSolanaTransactionFromBase64(gatewayTokenTransaction)
        : undefined;
    return Object.assign(Object.assign({}, useGateway()), { gatewayToken: solanaGatewayToken, gatewayTokenTransaction: solanaGatewayTokenTransaction });
};
