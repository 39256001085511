import { useCallback } from 'react';
import { prefixLogger } from '../logger';
const logDebug = prefixLogger('useUserInteraction').debug;
const logError = prefixLogger('useUserInteraction').error;
const useUserInteraction = ({ wallet }, state, dispatch) => {
    const { refreshTimeoutId, gatekeeperNetworkAddress } = state;
    /**
     * handle the user initiating an issuance request
     */
    const requestGatewayToken = useCallback(async () => {
        logDebug('requestGatewayToken called', { wallet, gatekeeperNetworkAddress });
        if (!wallet || !wallet.publicKey || !gatekeeperNetworkAddress) {
            logError('requestGatewayToken: Required parameters not set to initiate flow');
            return;
        }
        dispatch({ type: 'userInteraction_check_gatewayToken_status', token: state.gatewayToken });
    }, [refreshTimeoutId, state.gatewayToken, wallet === null || wallet === void 0 ? void 0 : wallet.publicKey, gatekeeperNetworkAddress]);
    return {
        requestGatewayToken,
    };
};
export default useUserInteraction;
