import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { findGatewayToken, State } from '@identity.com/solana-gateway-ts';
import { useEffect, useRef, useState } from 'react';
import BadgeSvg from './Badge.svg';
// Used to avoid making multiple blockchain calls when rerendering
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}
const Badge = ({ connection, gatekeeperNetwork, publicKey }) => {
    const [token, setToken] = useState();
    const prevGKN = usePrevious(gatekeeperNetwork === null || gatekeeperNetwork === void 0 ? void 0 : gatekeeperNetwork.toBase58());
    const prevPubkey = usePrevious(publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58());
    const prevConnectionUrl = usePrevious(connection.rpcEndpoint);
    useEffect(() => {
        // only make another call if anything changes, as we cannot
        // rely on useEffect to protect us from this
        if ((gatekeeperNetwork === null || gatekeeperNetwork === void 0 ? void 0 : gatekeeperNetwork.toBase58()) !== prevGKN ||
            (publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58()) !== prevPubkey ||
            connection.rpcEndpoint !== prevConnectionUrl) {
            // only set the GT if one was found
            findGatewayToken(connection, publicKey, gatekeeperNetwork).then((gt) => setToken(gt || undefined));
        }
    }, [gatekeeperNetwork === null || gatekeeperNetwork === void 0 ? void 0 : gatekeeperNetwork.toBase58(), publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58(), connection]);
    return (token === null || token === void 0 ? void 0 : token.state) === State.ACTIVE ? (_jsx("a", Object.assign({ href: "https://www.civic.com", target: "_blank", rel: "noreferrer", "data-testid": "badgeLink" }, { children: _jsx(BadgeSvg, {}) }))) : (_jsx(_Fragment, {}));
};
export default Badge;
