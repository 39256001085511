import { prefixLogger } from '../logger';
import { CivicPassMessageAction, GatewayStatus, RefreshTokenState, TokenIssuanceState } from '../types';
import { gatewayStatusIfAllowed, resetState, statusFromToken } from './utils';
import { autoShowIframe } from '../utils/status';
const logDebug = prefixLogger('reducer').debug;
export const combineReducers = (...reducers) => (state, action) => reducers.reduce((newState, reducer) => reducer(newState, action), state);
export const reducer = (state, action) => {
    var _a;
    const gatewayToken = action.token || state.gatewayToken;
    const gatewayStatus = gatewayToken
        ? statusFromToken(state, gatewayToken)
        : gatewayStatusIfAllowed(GatewayStatus.UNKNOWN, state);
    const updatedState = Object.assign(Object.assign({}, state), { gatewayStatus });
    // eslint-disable-next-line no-prototype-builtins
    const tokenCreationInProgress = !!((_a = updatedState.civicPass.responsePayload) === null || _a === void 0 ? void 0 : _a.hasOwnProperty(CivicPassMessageAction.ISSUANCE));
    switch (action.type) {
        case 'startWalletPowo': {
            return Object.assign(Object.assign({}, updatedState), { walletPowoInProgress: true });
        }
        case 'walletPowoComplete': {
            logDebug('tokenCreationInProgress', { tokenCreationInProgress });
            return Object.assign(Object.assign({}, updatedState), { 
                // only move to IN_REVIEW if it's during the initial token creation flow
                gatewayStatus: tokenCreationInProgress ? GatewayStatus.IN_REVIEW : updatedState.gatewayStatus, walletPowoInProgress: false, firstTokenCheck: false });
        }
        case 'handleOwnerSendsTransactionComplete': {
            logDebug('tokenCreationInProgress', { tokenCreationInProgress });
            const { signedTx } = action;
            return Object.assign(Object.assign({}, updatedState), { tokenIssuanceState: TokenIssuanceState.PENDING_ONCHAIN_CONFIRMATION, 
                // only move to IN_REVIEW if it's during the initial token creation flow
                gatewayStatus: tokenCreationInProgress ? GatewayStatus.IN_REVIEW : updatedState.gatewayStatus, walletPowoInProgress: false, firstTokenCheck: false, signedTx });
        }
        case 'walletPowoIncomplete': {
            logDebug('tokenCreationInProgress', { tokenCreationInProgress });
            return Object.assign(Object.assign({}, state), { powoFinished: false, refreshTokenState: RefreshTokenState.IN_PROGRESS });
        }
        case 'tokenChange':
            return Object.assign(Object.assign({}, state), { powoFinished: false, gatewayToken: action.token || state.gatewayToken, tokenIssuanceState: TokenIssuanceState.COMPLETED });
        case 'powoComplete':
            return Object.assign(Object.assign({}, updatedState), { gatewayStatus: GatewayStatus.PROOF_OF_WALLET_OWNERSHIP, powoFinished: true, powoRequested: undefined, refreshTimeoutId: undefined, refreshTokenState: state.refreshTokenState === RefreshTokenState.IN_PROGRESS
                    ? RefreshTokenState.REQUIRES_POWO
                    : state.refreshTokenState });
        case 'walletDisconnected':
            return resetState(state);
        case 'requestGatekeeperIssuance':
            return Object.assign(Object.assign({}, updatedState), { powoFinished: false, walletPowoInProgress: false, tokenIssuanceState: TokenIssuanceState.IN_PROGRESS });
        case 'requestGatekeeperIssuanceComplete':
            return Object.assign(Object.assign({}, updatedState), { tokenIssuanceState: !state.ownerSigns
                    ? TokenIssuanceState.PENDING_ONCHAIN_CONFIRMATION
                    : TokenIssuanceState.IN_PROGRESS, gatewayTokenTransaction: action.gatewayTokenTransaction, civicPass: Object.assign(Object.assign({}, updatedState.civicPass), { responsePayload: undefined }) });
        case 'requestGatekeeperIssuanceInReview':
            return Object.assign(Object.assign({}, updatedState), { tokenIssuanceState: TokenIssuanceState.IN_PARTNER_REVIEW, pending: action.pending, civicPass: Object.assign(Object.assign({}, updatedState.civicPass), { responsePayload: undefined }) });
        case 'requestGatekeeperIssuanceRejected':
            return Object.assign(Object.assign({}, state), { gatewayStatus: GatewayStatus.REJECTED, tokenIssuanceState: TokenIssuanceState.FAILED });
        case 'requestGatekeeperIssuanceFailed':
            return Object.assign(Object.assign({}, resetState(state)), { iframeMinimized: !autoShowIframe(state), renderIframe: true, gatewayStatus: GatewayStatus.ERROR, tokenIssuanceState: TokenIssuanceState.FAILED });
        case 'refreshAttemptDone':
            return Object.assign(Object.assign({}, updatedState), { refreshInProgress: false });
        case 'updateStateWithProps':
            logDebug('updateStateWithProps', action);
            return Object.assign(Object.assign({}, state), { renderIframe: false, redirectUrl: action.redirectUrl, ownerSigns: action.ownerSigns, inputExpiryMarginSeconds: action.inputExpiryMarginSeconds, partnerAppId: action.partnerAppId });
        case 'resetStateWithProps':
            logDebug('resetStateWithProps', action);
            return Object.assign(Object.assign({}, resetState(state)), { stage: action.stage, walletAddress: action.walletAddress, gatekeeperNetworkAddress: action.gatekeeperNetworkAddress, chainType: action.chainType, chainNetwork: action.chainNetwork, did: action.did });
        case 'tokenNotFoundError':
            return Object.assign(Object.assign({}, state), { gatewayStatus: GatewayStatus.ERROR });
        default:
            return state;
    }
};
