export var State;
(function (State) {
    State["ACTIVE"] = "ACTIVE";
    State["REVOKED"] = "REVOKED";
    State["FROZEN"] = "FROZEN";
})(State || (State = {}));
export var TokenState;
(function (TokenState) {
    TokenState["REQUESTED"] = "REQUESTED";
    TokenState["ACTIVE"] = "ACTIVE";
    TokenState["REVOKED"] = "REVOKED";
    TokenState["FROZEN"] = "FROZEN";
    TokenState["REJECTED"] = "REJECTED";
})(TokenState || (TokenState = {}));
export var GatekeeperAPIStatus;
(function (GatekeeperAPIStatus) {
    GatekeeperAPIStatus[GatekeeperAPIStatus["REQUESTING"] = 0] = "REQUESTING";
    GatekeeperAPIStatus[GatekeeperAPIStatus["NOT_REQUESTED"] = 404] = "NOT_REQUESTED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["REQUESTED"] = 202] = "REQUESTED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["ISSUED"] = 200] = "ISSUED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["ISSUED_EXPIRY_APPROACHING"] = 205] = "ISSUED_EXPIRY_APPROACHING";
    GatekeeperAPIStatus[GatekeeperAPIStatus["ISSUED_EXPIRED"] = 426] = "ISSUED_EXPIRED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["LOCATION_NOT_SUPPORTED"] = 401] = "LOCATION_NOT_SUPPORTED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["REJECTED"] = 403] = "REJECTED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["VPN_NOT_SUPPORTED"] = 421] = "VPN_NOT_SUPPORTED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["ISSUED_LOCATION_NOT_SUPPORTED"] = 412] = "ISSUED_LOCATION_NOT_SUPPORTED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["ISSUED_VPN_NOT_SUPPORTED"] = 451] = "ISSUED_VPN_NOT_SUPPORTED";
    GatekeeperAPIStatus[GatekeeperAPIStatus["SERVER_FAILURE"] = 500] = "SERVER_FAILURE";
    GatekeeperAPIStatus[GatekeeperAPIStatus["REQUESTED_RETRIES_EXHAUSTED"] = 408] = "REQUESTED_RETRIES_EXHAUSTED";
})(GatekeeperAPIStatus || (GatekeeperAPIStatus = {}));
export var GatewayStatus;
(function (GatewayStatus) {
    GatewayStatus[GatewayStatus["UNKNOWN"] = 0] = "UNKNOWN";
    GatewayStatus[GatewayStatus["CHECKING"] = 1] = "CHECKING";
    GatewayStatus[GatewayStatus["NOT_REQUESTED"] = 2] = "NOT_REQUESTED";
    GatewayStatus[GatewayStatus["COLLECTING_USER_INFORMATION"] = 3] = "COLLECTING_USER_INFORMATION";
    GatewayStatus[GatewayStatus["PROOF_OF_WALLET_OWNERSHIP"] = 4] = "PROOF_OF_WALLET_OWNERSHIP";
    GatewayStatus[GatewayStatus["IN_REVIEW"] = 5] = "IN_REVIEW";
    GatewayStatus[GatewayStatus["REJECTED"] = 6] = "REJECTED";
    GatewayStatus[GatewayStatus["REVOKED"] = 7] = "REVOKED";
    GatewayStatus[GatewayStatus["FROZEN"] = 8] = "FROZEN";
    GatewayStatus[GatewayStatus["ACTIVE"] = 9] = "ACTIVE";
    GatewayStatus[GatewayStatus["ERROR"] = 10] = "ERROR";
    GatewayStatus[GatewayStatus["LOCATION_NOT_SUPPORTED"] = 11] = "LOCATION_NOT_SUPPORTED";
    GatewayStatus[GatewayStatus["VPN_NOT_SUPPORTED"] = 12] = "VPN_NOT_SUPPORTED";
    GatewayStatus[GatewayStatus["REFRESH_TOKEN_REQUIRED"] = 13] = "REFRESH_TOKEN_REQUIRED";
    GatewayStatus[GatewayStatus["VALIDATING_USER_INFORMATION"] = 14] = "VALIDATING_USER_INFORMATION";
    GatewayStatus[GatewayStatus["USER_INFORMATION_VALIDATED"] = 15] = "USER_INFORMATION_VALIDATED";
    GatewayStatus[GatewayStatus["USER_INFORMATION_REJECTED"] = 16] = "USER_INFORMATION_REJECTED";
})(GatewayStatus || (GatewayStatus = {}));
// An extended status that provides more context on a gateway status, used to determine internal UI transitions, Not exposed in the public API
export var ExtendedGatewayStatus;
(function (ExtendedGatewayStatus) {
    // Set the detailed enum to a distinct numeric range so that it doesn't conflict with the GatewayStatus values
    ExtendedGatewayStatus[ExtendedGatewayStatus["AWAITING_OWNER_TRANSACTION"] = 100] = "AWAITING_OWNER_TRANSACTION";
    ExtendedGatewayStatus[ExtendedGatewayStatus["CHAIN_TIMEOUT_ERROR"] = 101] = "CHAIN_TIMEOUT_ERROR";
    ExtendedGatewayStatus[ExtendedGatewayStatus["CONFIRM_OWNER_TRANSACTION"] = 102] = "CONFIRM_OWNER_TRANSACTION";
    ExtendedGatewayStatus[ExtendedGatewayStatus["CHAIN_TRANSACTION_ERROR"] = 103] = "CHAIN_TRANSACTION_ERROR";
    ExtendedGatewayStatus[ExtendedGatewayStatus["TOKEN_IN_PARTNER_REVIEW"] = 104] = "TOKEN_IN_PARTNER_REVIEW";
    ExtendedGatewayStatus[ExtendedGatewayStatus["TOKEN_REFRESH_IN_REVIEW"] = 105] = "TOKEN_REFRESH_IN_REVIEW";
})(ExtendedGatewayStatus || (ExtendedGatewayStatus = {}));
export var TokenIssuanceState;
(function (TokenIssuanceState) {
    TokenIssuanceState[TokenIssuanceState["NOT_REQUESTED"] = 0] = "NOT_REQUESTED";
    TokenIssuanceState[TokenIssuanceState["IN_PROGRESS"] = 1] = "IN_PROGRESS";
    TokenIssuanceState[TokenIssuanceState["IN_PARTNER_REVIEW"] = 2] = "IN_PARTNER_REVIEW";
    TokenIssuanceState[TokenIssuanceState["PENDING_ONCHAIN_CONFIRMATION"] = 3] = "PENDING_ONCHAIN_CONFIRMATION";
    TokenIssuanceState[TokenIssuanceState["COMPLETED"] = 4] = "COMPLETED";
    TokenIssuanceState[TokenIssuanceState["FAILED"] = 5] = "FAILED";
})(TokenIssuanceState || (TokenIssuanceState = {}));
export var CivicPassIssuanceStatus;
(function (CivicPassIssuanceStatus) {
    CivicPassIssuanceStatus[CivicPassIssuanceStatus["NOT_REQUESTED"] = 0] = "NOT_REQUESTED";
    CivicPassIssuanceStatus[CivicPassIssuanceStatus["REQUESTED"] = 1] = "REQUESTED";
    CivicPassIssuanceStatus[CivicPassIssuanceStatus["VERIFIED"] = 2] = "VERIFIED";
    CivicPassIssuanceStatus[CivicPassIssuanceStatus["FAILED"] = 3] = "FAILED";
})(CivicPassIssuanceStatus || (CivicPassIssuanceStatus = {}));
export var RefreshTokenState;
(function (RefreshTokenState) {
    RefreshTokenState[RefreshTokenState["NOT_REQUIRED"] = 0] = "NOT_REQUIRED";
    RefreshTokenState[RefreshTokenState["CHECK_TOKEN_EXPIRATION"] = 1] = "CHECK_TOKEN_EXPIRATION";
    RefreshTokenState[RefreshTokenState["WAIT_FOR_ON_CHAIN"] = 2] = "WAIT_FOR_ON_CHAIN";
    RefreshTokenState[RefreshTokenState["IN_PROGRESS"] = 3] = "IN_PROGRESS";
    RefreshTokenState[RefreshTokenState["REQUIRES_POWO"] = 4] = "REQUIRES_POWO";
    RefreshTokenState[RefreshTokenState["COMPLETED"] = 5] = "COMPLETED";
    RefreshTokenState[RefreshTokenState["CANCELLED"] = 6] = "CANCELLED";
    RefreshTokenState[RefreshTokenState["FAILED"] = 7] = "FAILED";
})(RefreshTokenState || (RefreshTokenState = {}));
export var LogLevel;
(function (LogLevel) {
    LogLevel["DEBUG"] = "debug";
    LogLevel["INFO"] = "info";
    LogLevel["WARN"] = "warn";
    LogLevel["ERROR"] = "error";
})(LogLevel || (LogLevel = {}));
export var FetchStatus;
(function (FetchStatus) {
    FetchStatus["FETCHING"] = "fetching";
    FetchStatus["COMPLETE"] = "complete";
    FetchStatus["ERROR"] = "error";
})(FetchStatus || (FetchStatus = {}));
export var ChainType;
(function (ChainType) {
    ChainType["SOLANA"] = "solana";
    ChainType["ETHEREUM"] = "ethereum";
    ChainType["CASPER"] = "casper";
})(ChainType || (ChainType = {}));
export var SignatureMethod;
(function (SignatureMethod) {
    SignatureMethod[SignatureMethod["TRANSACTION"] = 0] = "TRANSACTION";
    SignatureMethod[SignatureMethod["MESSAGE"] = 1] = "MESSAGE";
})(SignatureMethod || (SignatureMethod = {}));
export var CivicPassMessageEventResult;
(function (CivicPassMessageEventResult) {
    CivicPassMessageEventResult["SUCCESS"] = "success";
    CivicPassMessageEventResult["FAILURE"] = "failure";
    CivicPassMessageEventResult["CANCELLED"] = "cancelled";
    CivicPassMessageEventResult["IN_PROGRESS"] = "inProgress";
    CivicPassMessageEventResult["ERROR"] = "error";
})(CivicPassMessageEventResult || (CivicPassMessageEventResult = {}));
export var CivicPassMessageAction;
(function (CivicPassMessageAction) {
    CivicPassMessageAction["ISSUANCE"] = "issuance";
    CivicPassMessageAction["CONFIRM_TRANSACTION"] = "confirmTransaction";
    CivicPassMessageAction["TOKEN_FROZEN"] = "tokenFrozen";
    CivicPassMessageAction["TOKEN_ACTIVE"] = "tokenActive";
    CivicPassMessageAction["TOKEN_REVOKED"] = "tokenRevoked";
    CivicPassMessageAction["TOKEN_REJECTED"] = "tokenRejected";
    CivicPassMessageAction["TOKEN_IN_REVIEW"] = "tokenInReview";
    CivicPassMessageAction["TOKEN_REFRESH_IN_REVIEW"] = "tokenRefreshInReview";
    CivicPassMessageAction["TOKEN_IN_PARTNER_REVIEW"] = "tokenInPartnerReview";
    CivicPassMessageAction["FAILED_IP_CHECK"] = "failedIpCheck";
    CivicPassMessageAction["FAILED_VPN_CHECK"] = "failedVpnCheck";
    CivicPassMessageAction["REFRESH"] = "refresh";
    CivicPassMessageAction["PROOF_OF_WALLET_OWNERSHIP"] = "proofOfWalletOwnership";
    CivicPassMessageAction["STATUS"] = "status";
    CivicPassMessageAction["ERROR"] = "error";
    CivicPassMessageAction["CHAIN_ERROR"] = "chainError";
    CivicPassMessageAction["SIGN_TRANSACTION"] = "signTransaction";
    CivicPassMessageAction["AWAITING_TRANSACTION_CONFIRMATION"] = "awaitingTransactionConfirmation";
})(CivicPassMessageAction || (CivicPassMessageAction = {}));
export var ValidationStatus;
(function (ValidationStatus) {
    // The validation is in progress
    ValidationStatus["COLLECTING"] = "COLLECTING";
    // The validation is busy processing
    ValidationStatus["PROCESSING"] = "PROCESSING";
    // The validation is in review
    ValidationStatus["IN_REVIEW"] = "IN_REVIEW";
    // The validation is completed successfully
    ValidationStatus["COMPLETED"] = "COMPLETED";
    // The validation failed
    ValidationStatus["FAILED"] = "FAILED";
    // The validation process does not exist
    ValidationStatus["NOT_FOUND"] = "NOT_FOUND";
})(ValidationStatus || (ValidationStatus = {}));
export class ChainTransactionError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, ChainTransactionError.prototype); // required for instance checking
    }
}
export var FlowType;
(function (FlowType) {
    FlowType["ISSUANCE"] = "issuance";
    FlowType["REFRESH"] = "refresh";
    FlowType["STATUS"] = "status";
})(FlowType || (FlowType = {}));
