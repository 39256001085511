import LogLocal from './local.logger';
import { LoggingProvider } from './provider.logger';
const localLogger = new LoggingProvider(new LogLocal());
const prefixDebug = (prefix, globalPrefix) => (message, obj = null) => localLogger.debug(`[${globalPrefix}][${prefix}] ${message}`, obj);
const prefixError = (prefix, globalPrefix) => (message, obj = null) => localLogger.error(`[${globalPrefix}][${prefix}] ${message}`, obj);
const prefixWarn = (prefix, globalPrefix) => (message, obj = null) => localLogger.warn(`[${globalPrefix}][${prefix}] ${message}`, obj);
const prefixInfo = (prefix, globalPrefix) => (message, obj = null) => localLogger.info(`[${globalPrefix}][${prefix}] ${message}`, obj);
export const prefixLogger = (prefix, globalPrefix = 'CivicGatewayRC') => {
    return {
        setLogLevel: localLogger.setLogLevel,
        debug: prefixDebug(prefix, globalPrefix),
        error: prefixError(prefix, globalPrefix),
        warn: prefixWarn(prefix, globalPrefix),
        info: prefixInfo(prefix, globalPrefix),
    };
};
export default localLogger;
