import { jsx as _jsx } from "react/jsx-runtime";
import { GatewayStatus } from '../types';
import { IconLogo } from './IconLogo';
import { IconError } from './IconError';
import { IconLoading } from './IconLoading';
import { IconSuccess } from './IconSuccess';
import { IconWarning } from './IconWarning';
const buttonIconStyle = {
    width: '25px',
    height: '25px',
    fill: '#3AB03E',
    position: 'absolute',
    left: '15px',
    top: '10px',
};
const Icon = {
    LOGO: _jsx(IconLogo, { style: buttonIconStyle }),
    ERROR: _jsx(IconError, { style: buttonIconStyle }),
    SPINNER: _jsx(IconLoading, { style: buttonIconStyle }),
    SUCCESS: _jsx(IconSuccess, { style: buttonIconStyle }),
    WARNING: _jsx(IconWarning, { style: buttonIconStyle }),
};
// eslint-disable-next-line import/prefer-default-export
export const getIcon = (status) => {
    switch (status) {
        case GatewayStatus.IN_REVIEW:
        case GatewayStatus.CHECKING:
            return Icon.SPINNER;
        case GatewayStatus.ACTIVE:
            return Icon.LOGO;
        case GatewayStatus.REFRESH_TOKEN_REQUIRED:
        case GatewayStatus.FROZEN:
        case GatewayStatus.REJECTED:
        case GatewayStatus.REVOKED:
        case GatewayStatus.LOCATION_NOT_SUPPORTED:
        case GatewayStatus.VPN_NOT_SUPPORTED:
        case GatewayStatus.USER_INFORMATION_REJECTED:
            return Icon.WARNING;
        case GatewayStatus.ERROR:
            return Icon.ERROR;
        default:
            return Icon.LOGO;
    }
};
export const getButtonText = (status) => {
    switch (status) {
        case GatewayStatus.IN_REVIEW:
            return 'Reviewing';
        case GatewayStatus.CHECKING:
            return 'Collecting';
        case GatewayStatus.ACTIVE:
            return 'Active';
        case GatewayStatus.FROZEN:
        case GatewayStatus.REJECTED:
        case GatewayStatus.REVOKED:
            return 'Attention';
        case GatewayStatus.ERROR:
            return 'Error';
        case GatewayStatus.PROOF_OF_WALLET_OWNERSHIP:
            return 'Confirm';
        case GatewayStatus.LOCATION_NOT_SUPPORTED:
        case GatewayStatus.VPN_NOT_SUPPORTED:
            return 'Not supported';
        case GatewayStatus.COLLECTING_USER_INFORMATION:
        case GatewayStatus.USER_INFORMATION_VALIDATED:
            return 'Resume';
        case GatewayStatus.VALIDATING_USER_INFORMATION:
            return 'Review';
        case GatewayStatus.USER_INFORMATION_REJECTED:
            return 'Failed';
        default:
            return 'Get Pass';
    }
};
export const isDisabled = (state) => {
    return state === GatewayStatus.CHECKING;
};
export const getTokenDescription = (status) => {
    switch (status) {
        case GatewayStatus.UNKNOWN:
            return 'The current state of the Civic Pass is unknown.';
        case GatewayStatus.CHECKING:
            return 'The blockchain is being queried to find an existing Civic Pass.';
        case GatewayStatus.NOT_REQUESTED:
            return 'A Civic Pass has not been requested.';
        case GatewayStatus.IN_REVIEW:
            return 'Your Civic Pass is pending review.';
        case GatewayStatus.REJECTED:
            return 'Your Civic Pass request was rejected';
        case GatewayStatus.REVOKED:
            return 'Your Civic Pass has been revoked. Please contact support.';
        case GatewayStatus.FROZEN:
            return 'Your Civic Pass has been frozen. Please contact support.';
        case GatewayStatus.ACTIVE:
            return 'Your Civic Pass is currently active.';
        case GatewayStatus.ERROR:
            return 'Something went wrong, please try again.';
        case GatewayStatus.PROOF_OF_WALLET_OWNERSHIP:
            return 'Please confirm wallet ownership by tapping on the button again';
        case GatewayStatus.LOCATION_NOT_SUPPORTED:
            return 'Your location is not supported at this time';
        case GatewayStatus.VPN_NOT_SUPPORTED:
            return 'VPN usage is not supported at this time';
        case GatewayStatus.VALIDATING_USER_INFORMATION:
            return 'The validation process is currently being reviewed. Please be patient.';
        case GatewayStatus.USER_INFORMATION_VALIDATED:
            return 'The validation process has been reviewed and needs to be completed.';
        case GatewayStatus.COLLECTING_USER_INFORMATION:
            return 'The process is still in progess and needs to be completed.';
        case GatewayStatus.USER_INFORMATION_REJECTED:
            return 'The validation process was rejected.';
        case GatewayStatus.REFRESH_TOKEN_REQUIRED:
            return 'Your token needs to be refreshed';
        default:
            return 'Please wait for the state to be updated.';
    }
};
