var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line import/prefer-default-export
export const IconLogo = (_a) => {
    var props = __rest(_a, []);
    return (_jsxs("svg", Object.assign({ width: "46", height: "46", viewBox: "0 0 46 46", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [_jsx("path", { d: "M40.8526 28.6242C39.9579 35.5669 34.01 40.9501 26.8238 40.9501H18.845C11.0429 40.9501 4.69549 34.6062 4.69549 26.8085V18.8345C4.69549 11.0368 11.0429 4.69298 18.845 4.69298H26.8238C34.01 4.69298 39.9577 10.0763 40.8526 17.019H45.5795C44.6624 7.48238 36.602 0 26.8238 0H18.845C8.45384 0 0 8.44921 0 18.8345V26.8085C0 37.194 8.45386 45.643 18.845 45.643H26.8238C36.602 45.643 44.6624 38.1605 45.5795 28.6242H40.8526Z", fill: "#FF6B4E" }), _jsx("path", { d: "M9.44088 5.39129C9.44088 2.84845 7.3785 0.787231 4.83422 0.787231C2.29008 0.787231 0.227539 2.84843 0.227539 5.39129C0.227539 7.17054 1.23907 8.71115 2.7173 9.47765L1.01 16.8562H8.65842L6.95128 9.47765C8.42934 8.71115 9.44088 7.17054 9.44088 5.39129Z", fill: "#FF6B4E", transform: "translate(18.23,14.79)" })] })));
};
