import { prefixLogger } from '../logger';
const logDebug = prefixLogger('utils').debug;
export const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
export const pollUntilConditionMet = async (fnToRun, conditionChecker, interval = 2000, retries = 20) => {
    logDebug('pollUntilConditionMet', { name: fnToRun.name, conditionChecker, interval, retries });
    if (retries <= 0) {
        logDebug('WaitForStatusChange - no more retries');
        throw new Error(`pollUntilConditionMet all retries used`);
    }
    logDebug(`calling ${fnToRun.name} retries = ${retries}`);
    const result = await fnToRun().catch((error) => {
        // retry if the function rejects the promise.
        logDebug(`error calling ${fnToRun.name} retries = ${retries}`);
        if (retries <= 0)
            throw error;
        return null;
    });
    logDebug(`result of ${fnToRun.name} = ${result}`);
    if (result && conditionChecker(result)) {
        return result;
    }
    logDebug(`Waiting ${interval}ms before running ${fnToRun.name} and checking condition ${conditionChecker.name}`);
    await sleep(interval);
    return pollUntilConditionMet(fnToRun, conditionChecker, interval, retries - 1);
};
