export const ErrorCode = {
    INSUFFICIENT_FUNDS: '0x1',
    SIGN_TRANSACTION_ERROR: '0x2',
    SEND_TRANSACTION_ERROR: '0x3',
    CUSTOM_HANDLE_TRANSACTION_ERROR: '0x4',
    POWO_ERROR: '0x5',
};
export class ChainError extends Error {
    constructor(message, errorCode, logger) {
        super(message);
        this.errorCode = errorCode;
        logger === null || logger === void 0 ? void 0 : logger.error(`ChainError: ${message} throwing code ${errorCode}`);
        Object.setPrototypeOf(this, ChainError.prototype); // required for instance checking
    }
}
export class AlreadyRefreshedError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, AlreadyRefreshedError.prototype); // required for instance checking
    }
}
export class AbortedError extends Error {
    constructor() {
        super('Abort controller aborted');
        Object.setPrototypeOf(this, AbortedError.prototype); // required for instance checking
    }
}
export const throwIfAborted = (controller) => (result) => {
    if (controller.signal.aborted)
        throw new AbortedError();
    return Promise.resolve(result);
};
