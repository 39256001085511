import { reducer as appReducer, combineReducers } from './reducer';
import { reducer as useGatekeeperRecordReducer } from '../useHooks/useGatekeeperRecord';
import { reducer as useCivicPass } from '../useHooks/useCivicPass';
import { reducer as useChainReducer } from '../useHooks/useChain';
import { reducer as useRefreshReducer } from '../useHooks/useRefresh';
const withInstanceIdCheck = (reducer) => (state, action) => {
    if (action.instanceId !== state.instanceId) {
        return state; // Return the current state without any changes
    }
    // If instanceId matches, call the actual reducer
    return reducer(state, action);
};
const reducer = combineReducers(withInstanceIdCheck(useCivicPass), withInstanceIdCheck(appReducer), withInstanceIdCheck(useGatekeeperRecordReducer), withInstanceIdCheck(useChainReducer), withInstanceIdCheck(useRefreshReducer));
export default reducer;
