import { useCallback, useEffect } from 'react';
import { GatekeeperAPIStatus, GatewayStatus, FetchStatus } from '../types';
import { prefixLogger } from '../logger';
import { gatewayStatusIfAllowed } from '../useReducer/utils';
const logDebug = prefixLogger('useChain').debug;
export const reducer = (state, action) => {
    switch (action.type) {
        case 'tokenExpectedTimerCleared':
            return Object.assign(Object.assign({}, state), { tokenExpectedTimerId: undefined });
        case 'tokenExpectedTimerAdded':
            return Object.assign(Object.assign({}, state), { tokenExpectedTimerId: action.listenerId });
        case 'tokenListenerAdded':
            return Object.assign(Object.assign({}, state), { tokenCreatedOrChangedListenerId: action.listenerId });
        case 'tokenOnChainFound':
            return Object.assign(Object.assign({}, state), { fetchOnChainStatus: FetchStatus.COMPLETE });
        case 'tokenOnChainUnknown':
        case 'tokenOnChainNotFound':
            return Object.assign(Object.assign({}, state), { fetchOnChainStatus: FetchStatus.COMPLETE, gatewayStatus: gatewayStatusIfAllowed(GatewayStatus.NOT_REQUESTED, Object.assign(Object.assign({}, state), { fetchOnChainStatus: FetchStatus.COMPLETE })) });
        case 'tokenOnChainError':
            return Object.assign(Object.assign({}, state), { userInitiatedFlow: false, fetchOnChainStatus: FetchStatus.ERROR, gatewayStatus: GatewayStatus.ERROR });
        case 'tokenOnChainFetch':
            return Object.assign(Object.assign({}, state), { fetchOnChainStatus: FetchStatus.FETCHING });
        default:
            return state;
    }
};
const useChain = ({ chainImplementation, onGatewayTokenCreatedOrChanged, }, state, dispatch) => {
    const { gatekeeperRecordState, tokenCreatedOrChangedListenerId, gatekeeperNetworkAddress } = state;
    const removeOnChainListener = useCallback((listenerId) => {
        try {
            if (chainImplementation) {
                logDebug('Removing onChainListener with id: ', listenerId);
                chainImplementation.removeOnGatewayTokenChangeListener(listenerId);
            }
        }
        catch (error) {
            logDebug('Error removing on chain listener', error);
        }
    }, [chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.initProps]);
    const dispatchListenerAdded = (listenerId) => {
        dispatch({ type: 'tokenListenerAdded', listenerId });
    };
    /**
     * listen to the blockchain for a token being created or changed, removing any existing listeners first
     */
    const addChainCreatedOrChangedListeners = useCallback((tokenChangeCallback) => {
        if (!chainImplementation || !chainImplementation.addOnGatewayTokenCreatedOrChangedListener) {
            throw new Error('Chain implementation must implement addOnGatewayTokenCreatedOrChangedListener');
        }
        if (tokenCreatedOrChangedListenerId !== undefined) {
            removeOnChainListener(tokenCreatedOrChangedListenerId);
        }
        const listenerId = chainImplementation.addOnGatewayTokenCreatedOrChangedListener(tokenChangeCallback);
        if (listenerId === undefined)
            return undefined;
        dispatchListenerAdded(listenerId);
        return listenerId;
    }, [chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.initProps, tokenCreatedOrChangedListenerId]);
    /**
     * listen to the blockchain for a token being changed-only (the case where the chainImplementation doesn't support listening for creation)
     * , any existing listeners are removed first
     */
    const addTokenChangeListener = useCallback(async (gatewayToken, tokenChangeCallback) => {
        if (!chainImplementation) {
            return Promise.reject();
        }
        if (tokenCreatedOrChangedListenerId !== undefined) {
            removeOnChainListener(tokenCreatedOrChangedListenerId);
        }
        const listenerId = await chainImplementation.addOnGatewayTokenChangeListener(gatewayToken, tokenChangeCallback);
        logDebug('Adding onChainListener with id: ', listenerId);
        return listenerId;
    }, [chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.initProps, tokenCreatedOrChangedListenerId]);
    /**
     * use the on-chain lookup utility findGatewayToken to retrieve a token from the chain
     * when a token is found, set up listeners to monitor any on-chain changes
     */
    const checkForOnChainToken = useCallback(async (onTokenFoundCallback) => {
        if (!chainImplementation || !gatekeeperNetworkAddress) {
            logDebug('checkForOnChainToken required inputs not present returning early');
            return;
        }
        try {
            logDebug('Fetching token from chain');
            const token = await chainImplementation.findGatewayToken();
            if (!token) {
                dispatch({ type: 'tokenOnChainNotFound' });
                return;
            }
            dispatch({ type: 'tokenOnChainFound' });
            logDebug('Token found', token);
            onTokenFoundCallback(token);
            // Determine if we should show the civicPass dialog when we have a gateway token
            const shouldcheckForOnChainToken = gatekeeperRecordState &&
                [
                    GatekeeperAPIStatus.ISSUED_EXPIRED,
                    GatekeeperAPIStatus.ISSUED_EXPIRY_APPROACHING,
                    GatekeeperAPIStatus.ISSUED_LOCATION_NOT_SUPPORTED,
                    GatekeeperAPIStatus.ISSUED_VPN_NOT_SUPPORTED,
                ].includes(gatekeeperRecordState);
            if (!shouldcheckForOnChainToken) {
                return;
            }
            dispatch({ type: 'civicPass_check_token_status', token });
        }
        catch (error) {
            dispatch({ type: 'tokenOnChainError' });
            throw error;
        }
    }, [chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.initProps, gatekeeperNetworkAddress, gatekeeperRecordState]);
    /**
     * Check token on chain
     */
    useEffect(() => {
        checkForOnChainToken(onGatewayTokenCreatedOrChanged);
    }, [chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.initProps]);
    const dispatchTokenExpectedTimerAdded = (listenerId) => {
        dispatch({ type: 'tokenExpectedTimerAdded', listenerId });
    };
    const dispatchTokenExpectedTimerCleared = (listenerId) => {
        dispatch({ type: 'tokenExpectedTimerCleared', listenerId });
    };
    return {
        removeOnChainListener,
        addChainCreatedOrChangedListeners,
        checkForOnChainToken,
        dispatchListenerAdded,
        dispatchTokenExpectedTimerAdded,
        dispatchTokenExpectedTimerCleared,
        addTokenChangeListener,
    };
};
export default useChain;
