import { prefixLogger } from '../logger';
import { ChainTransactionError, CivicPassMessageAction, GatekeeperAPIStatus, TokenIssuanceState, } from '../types';
import { isTokenActive } from '../utils/tokenUtils';
import { isGkApiStatusFailure, isGkApiStatusRequestedRetriesExhausted, isGkApiStatusTokenCreated, isGkApiStatusTokenPending, } from '../utils/gatekeeperClient';
import { gatewayTokenActionCreator } from '../actionCreator';
const logDebug = prefixLogger('PassOrchestrator').debug;
const logError = prefixLogger('PassOrchestrator').error;
const getResponsePayload = (state, payloadType) => { var _a; return (((_a = state.civicPass) === null || _a === void 0 ? void 0 : _a.responsePayload) || {})[payloadType]; };
const isIssuanceFlow = (state) => !!getResponsePayload(state, CivicPassMessageAction.ISSUANCE) ||
    [
        TokenIssuanceState.FAILED,
        TokenIssuanceState.IN_PROGRESS,
        TokenIssuanceState.IN_PARTNER_REVIEW,
        TokenIssuanceState.PENDING_ONCHAIN_CONFIRMATION,
    ].includes(state.tokenIssuanceState);
/**
 * The PassOrchestrator uses state (provided by the host-framework) to decide
 * which actions to emit based for different flows such as token issuance & refresh.
 */
export class PassOrchestrator {
    constructor(stateInterface, dispatchInterface, abortController, hookFns, chainImplementation, networkConfig, gatekeeperClient) {
        this.stateInterface = stateInterface;
        this.dispatchInterface = dispatchInterface;
        this.abortController = abortController;
        this.hookFns = hookFns;
        this.chainImplementation = chainImplementation;
        this.networkConfig = networkConfig;
        this.gatekeeperClient = gatekeeperClient;
    }
    static getInstance(stateInterface, dispatchInterface, abortController, hookFns, chainImplementation, networkConfig, gatekeeperClient) {
        const newInstance = new PassOrchestrator(stateInterface, dispatchInterface, abortController, hookFns, chainImplementation, networkConfig, gatekeeperClient);
        PassOrchestrator.instance = newInstance;
        return PassOrchestrator.instance;
    }
    get state() {
        return this.stateInterface.getState();
    }
    clearWaitForActiveTimer() {
        const { tokenExpectedTimerId } = this.state;
        logDebug(`clearWaitForActiveTimer: ${tokenExpectedTimerId}`);
        if (tokenExpectedTimerId) {
            clearTimeout(tokenExpectedTimerId);
            this.hookFns.dispatchTokenExpectedTimerCleared(tokenExpectedTimerId);
        }
    }
    /**
     * poll until a gatekeeper record is found, once active check the chain for a token
     * then dispatch a tokenChange event that will result in the token getting saved to state
     * start token refresh polling once a valid token is set
     */
    async pollForOnChainToken() {
        logDebug(`pollForOnChainToken`);
        const { walletAddress, ownerSigns } = this.state;
        if (walletAddress) {
            const actionCreator = gatewayTokenActionCreator({
                walletAddress,
                chainImplementation: this.chainImplementation,
                gatekeeperClient: this.gatekeeperClient,
                dispatch: this.dispatchIfNotAborted,
                networkConfig: this.networkConfig,
                ownerSigns,
            });
            await actionCreator.waitForGatewayToken({
                pollChainNumberRetries: this.networkConfig.pollChainNumberRetries,
                abortController: this.abortController,
            });
        }
        return null;
    }
    /**
     * This function runs when the timeout period for an expected on-chain token to be found is finished.
     * For owner signs/sends:
     *    - dispatch a timeout event to prompt the user to retry
     * For civic sends:
     *    - check the status of issuance with the gatekeeper:
     *        - if the retries have been exhausted, prompt the user to retry
     *        - if issuance is still pending, then retry after a delay (max 3 retries)
     *        - if all retries have been tried then dispatch an error to prompt the user to retry
     * @param numberOfRetries
     * @returns {Promise<void>}
     */
    async onTokenTimeoutEnd(numberOfRetries = 3) {
        var _a, _b;
        const { gatewayToken, walletAddress } = this.state;
        logDebug('onTokenTimeoutEnd', {
            gatewayToken: {
                expiryTime: (_a = this.state.gatewayToken) === null || _a === void 0 ? void 0 : _a.expiryTime,
                gatewayTokenState: (_b = this.state.gatewayToken) === null || _b === void 0 ? void 0 : _b.state,
            },
        });
        if (!walletAddress || !this.gatekeeperClient) {
            return;
        }
        if (!gatewayToken || !isTokenActive({ expiryTime: gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime, state: gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.state })) {
            logError('Could not find active gateway token on-chain');
            // If owner signs is true then fail the flow without checking the gatekeeper
            if (this.state.ownerSigns) {
                logError('Failed to find Gateway token on-chain with ownerSigns:true, failing');
                this.dispatchIfNotAborted({ type: 'civicPass_owner_transaction_timeout' });
                return;
            }
            logError('Failed to find Gateway token on-chain with ownerSigns:false, checking with gatekeeper');
            const gkApiStatus = await this.gatekeeperClient.getGatekeeperStatus(walletAddress);
            if (isGkApiStatusRequestedRetriesExhausted(gkApiStatus)) {
                logError('Retried exhausted', { gkApiStatus });
                this.dispatchIfNotAborted({ type: 'civicPass_requested_retries_exhausted' });
                return;
            }
            // if the token is still pending or in review then keep polling on-chain
            if (isGkApiStatusTokenPending(gkApiStatus) && numberOfRetries > 0) {
                // we don't want to wait the whole amount of time if we've got to here, so just 4 retries = 3 x 2 seconds, a further
                // 6 seconds before we will ask the GK API for status again
                setTimeout(() => this.onTokenTimeoutEnd(numberOfRetries - 1), 2000);
                return;
            }
            // retries have been exhausted and we still don't have a token
            // or the gatekeeper threw an error during issuance attempt
            if (isGkApiStatusTokenCreated(gkApiStatus) || isGkApiStatusFailure(gkApiStatus)) {
                logError('Failed to find Gateway token with gatekeeper status code', GatekeeperAPIStatus[gkApiStatus]);
                this.dispatchIfNotAborted({ type: 'tokenNotFoundError' });
                this.dispatchIfNotAborted({ type: 'civicPass_issuance_failure' });
            }
        }
    }
    dispatchIfNotAborted(value) {
        var _a;
        logDebug('dispatchIfNotAborted', value);
        if (this && !((_a = this.abortController) === null || _a === void 0 ? void 0 : _a.signal.aborted))
            this.dispatchInterface.dispatch(value);
    }
    /**
     * New token request flow for civic-sends:
     * use the issuance payload from the civic-pass iframe. The payload can optionally contain a proof requirement
     * if so, then wait for the proof requirement to be fulfilled before triggering an issuance request to the gatekeeper
     * when this flow completes successfully, the gatekeeper API should initiate a civic-sends gateway token issuance, if
     * all the strategy checks finish successfully
     */
    async newTokenRequestFlowCivicSends() {
        try {
            const { tokenIssuanceState } = this.state;
            logDebug(`newTokenRequestFlowCivicSends tokenIssuanceState: ${TokenIssuanceState[this.state.tokenIssuanceState]}`, this.state);
            if (tokenIssuanceState === TokenIssuanceState.NOT_REQUESTED) {
                const { payload } = getResponsePayload(this.state, CivicPassMessageAction.ISSUANCE);
                if (payload) {
                    logDebug('newTokenRequestFlowCivicSends payload: ', payload);
                    await this.hookFns.waitForGatekeeperIssuanceRequest({
                        payload,
                    });
                }
            }
            else if (tokenIssuanceState === TokenIssuanceState.PENDING_ONCHAIN_CONFIRMATION) {
                this.expectTokenCreatedOnChain();
            }
            else if (tokenIssuanceState === TokenIssuanceState.IN_PARTNER_REVIEW) {
                this.dispatchIfNotAborted({ type: 'civicPass_token_in_partner_review_status' });
            }
            else if (tokenIssuanceState === TokenIssuanceState.FAILED) {
                this.dispatchIfNotAborted({ type: 'civicPass_issuance_rejected' });
            }
        }
        catch (error) {
            this.dispatchIfNotAborted({ type: 'civicPass_issuance_failure' });
            logError('ERROR newTokenRequestFlow', error);
        }
    }
    /**
     * Sets up a flow to expect an on-chain token to be available within the network-based-config timeout
     */
    expectTokenCreatedOnChain() {
        const { tokenExpectedTimerId } = this.state;
        logDebug('expectTokenCreatedOnChain', { tokenExpectedTimerId });
        // if the chainImplementation has a created listener then wait for it to fire, otherwise use polling
        if (this.chainImplementation &&
            this.chainImplementation.addOnGatewayTokenCreatedOrChangedListener &&
            !tokenExpectedTimerId &&
            !isTokenActive(this.state.gatewayToken)) {
            const timeoutDuration = this.networkConfig.pollChainIntervalMilliseconds * this.networkConfig.pollChainNumberRetries;
            logDebug('setListenForActiveTokenTimer creating setTimeout', { timeoutDurationSeconds: timeoutDuration / 1000 });
            const expectTokenWithinIntervalTimer = setTimeout(() => !this.abortController.signal.aborted && this.onTokenTimeoutEnd(), timeoutDuration);
            logDebug('dispatchTokenExpectedTimerAdded', expectTokenWithinIntervalTimer);
            this.hookFns.dispatchTokenExpectedTimerAdded(expectTokenWithinIntervalTimer);
        }
        else {
            this.pollForOnChainToken();
        }
    }
    /**
     * New token request flow for owner-signs and sends:
     * use the issuance payload from the civic-pass iframe. The payload can optionally contain a proof requirement
     * if so, then wait for the proof requirement to be fulfilled before triggering the flow to prompt the user to sign
     * and send the transaction to send to chain. This flow will get run multiple times, as the tokenIssuanceState
     * changes. The last state in the orchestrator flow is PENDING_ONCHAIN_CONFIRMATION, once this is detected,
     * the transaction is in the hands of the user to sign, and the RC will trigger a 'awaiting owner signs transaction' screen
     */
    async newTokenRequestFlowOwnerSigns() {
        const { tokenIssuanceState } = this.state;
        logDebug('newTokenRequestFlowOwnerSigns', { tokenIssuanceState });
        try {
            if (tokenIssuanceState === TokenIssuanceState.NOT_REQUESTED) {
                const { payload } = getResponsePayload(this.state, CivicPassMessageAction.ISSUANCE);
                logDebug('newTokenRequestFlowOwnerSigns payload: ', payload);
                if (payload) {
                    await this.hookFns.waitForTransactionConfirm();
                    await this.hookFns.waitForGatekeeperIssuanceRequest({ payload });
                }
            }
            else if (tokenIssuanceState === TokenIssuanceState.IN_PROGRESS) {
                logDebug('this.state.gatewayTokenTransaction', this.state.gatewayTokenTransaction);
                if (this.state.gatewayTokenTransaction) {
                    await this.hookFns.waitForHandleTransaction(this.state.gatewayTokenTransaction);
                }
            }
            else if (tokenIssuanceState === TokenIssuanceState.PENDING_ONCHAIN_CONFIRMATION) {
                this.expectTokenCreatedOnChain();
                this.dispatchIfNotAborted({ type: 'civicPass_awaiting_owner_transaction' });
            }
            else if (tokenIssuanceState === TokenIssuanceState.FAILED) {
                this.dispatchIfNotAborted({ type: 'civicPass_issuance_rejected' });
            }
            else if (tokenIssuanceState === TokenIssuanceState.IN_PARTNER_REVIEW) {
                this.dispatchIfNotAborted({ type: 'civicPass_token_in_partner_review_status' });
            }
        }
        catch (error) {
            logError('ERROR newTokenRequestFlow', error);
            // chain transaction errors will be handled using a different flow and dispatch event
            if (!(error instanceof ChainTransactionError)) {
                this.dispatchIfNotAborted({ type: 'civicPass_issuance_failure' });
            }
        }
    }
    /**
     * Decide which flow to run depending on state, then run the flow
     * Currently supported flows:
     *    - issuance flow
     *    - post-gatewayToken creation flow
     *    - TODO: implement refresh flows
     */
    async orchestrate() {
        const { gatewayToken, ownerSigns } = this.state;
        logDebug('orchestrate', {
            issuanceFlow: isIssuanceFlow(this.state),
            tokenIssuanceState: TokenIssuanceState[this.state.tokenIssuanceState],
            gatewayToken,
        });
        if (gatewayToken) {
            // if we already have a listener to check if a pass is created, then just check if the token is active
            // if it's not active, a timer is already running to handle it not becoming active
            // if the chainImplementation doesn't support listening for creation, then we need to
            // add listeners for any on-chain token-changes
            if (this.chainImplementation && this.chainImplementation.addOnGatewayTokenCreatedOrChangedListener) {
                if (isTokenActive({ state: gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.state, expiryTime: gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime })) {
                    this.clearWaitForActiveTimer();
                }
            }
            else {
                this.hookFns.addTokenChangeListener(gatewayToken, this.hookFns.onGatewayTokenCreatedOrChanged);
            }
        }
        else if (isIssuanceFlow(this.state)) {
            if (ownerSigns) {
                await this.newTokenRequestFlowOwnerSigns();
            }
            else if (!ownerSigns) {
                await this.newTokenRequestFlowCivicSends();
            }
        }
    }
}
