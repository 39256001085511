import { State } from '../types';
const isApproachingExpiry = (currentExpiry, tokenExpirationMarginSeconds) => {
    const now = Math.floor(Date.now() / 1000);
    return currentExpiry - now <= tokenExpirationMarginSeconds;
};
export const hasExpired = (expiryTime) => {
    if (!expiryTime)
        return false;
    const now = Math.floor(Date.now() / 1000);
    return now >= expiryTime;
};
export const isTokenRefreshRequired = ({ gatewayToken, tokenExpirationMarginSeconds, }) => {
    const { expiryTime, state } = gatewayToken || {};
    const isActive = state === State.ACTIVE;
    if (!expiryTime || !isActive)
        return false;
    return hasExpired(expiryTime) || isApproachingExpiry(expiryTime, tokenExpirationMarginSeconds);
};
// Maximum allowed timeout for setInterval and setTimeout is 2147483647 (https://nodejs.org/api/timers.html)
export const MAX_ALLOWED_TIMEOUT = 2147483647;
export const nowSeconds = () => Math.floor(Date.now() / 1000);
/**
 * return the expiry time in milliseconds from the current time until the gateway token expires
 * taking into account a margin (if provided). If the interval is too large for a javascript timer
 * then return the maximum allowed timeout
 * @param {number} expiryTimeSeconds
 * @param {number} tokenExpirationMarginSeconds
 * @returns {number}
 */
export const getTokenRefreshIntervalMilliseconds = (expiryTimeSeconds, tokenExpirationMarginSeconds) => {
    const interval = expiryTimeSeconds - nowSeconds() - tokenExpirationMarginSeconds;
    const retVal = interval > 0 ? interval * 1000 : 0;
    return retVal >= MAX_ALLOWED_TIMEOUT ? MAX_ALLOWED_TIMEOUT - 1 : retVal;
};
export const isTokenActive = (token) => {
    if (!token)
        return false;
    if (token.state === State.ACTIVE || !token.expiryTime) {
        return true;
    }
    // token found but not active
    return false;
};
