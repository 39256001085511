import { v4 as uuid } from 'uuid';
import { FlowType, GatewayStatus } from '../types';
import { RC_FLOW_ID_PREFIX } from '../constants';
export const getFlowId = (action, state) => {
    var _a;
    // we only want to set a default flowId prefix if a flowId hasn't been set
    let prefix = state.flowId ? '' : `${FlowType.STATUS}_`;
    if (action.includes('refresh') ||
        [GatewayStatus.REFRESH_TOKEN_REQUIRED].includes(state.gatewayStatus)) {
        prefix = `${FlowType.REFRESH}_`;
    }
    else if (action.includes('issuance') ||
        [GatewayStatus.NOT_REQUESTED].includes(state.gatewayStatus)) {
        prefix = `${FlowType.ISSUANCE}_`;
    }
    return !state.flowId || state.flowId === '' || !((_a = state.flowId) === null || _a === void 0 ? void 0 : _a.includes(prefix))
        ? `${RC_FLOW_ID_PREFIX}_${prefix}${uuid()}`
        : state.flowId;
};
export const flowTypeFromFlowId = (flowId) => {
    if (`${flowId}`.includes('issuance')) {
        return FlowType.ISSUANCE;
    }
    if (`${flowId}`.includes('refresh')) {
        return FlowType.REFRESH;
    }
    return FlowType.STATUS;
};
