import { useCallback } from 'react';
import { prefixLogger } from '../logger';
import { ChainTransactionError } from '../types';
const logDebug = prefixLogger('useWallet').debug;
const logError = prefixLogger('useWallet').error;
const useWallet = (chainImplementation, wallet, { refreshTimeoutId, ownerTransactionConfirmed }, dispatch) => {
    /**
     * detect a wallet disconnecting and dispatch an event
     */
    const handleWalletDisconnect = useCallback(() => {
        if (!wallet || !wallet.publicKey) {
            logDebug('wallet disconnected');
            if (refreshTimeoutId) {
                logDebug('useEffect cleartimeout', refreshTimeoutId);
                clearTimeout(refreshTimeoutId);
                dispatch({ type: 'refresh_clear_timeout' });
            }
            dispatch({ type: 'walletDisconnected' });
        }
    }, [wallet === null || wallet === void 0 ? void 0 : wallet.publicKey, refreshTimeoutId]);
    /**
     * wait until the user has confirmed they want to continue the sign transaction flow
     * then resolve the promise
     */
    const waitForTransactionConfirm = useCallback(async () => {
        return new Promise((resolve) => {
            logDebug('waitForTransactionConfirm', { ownerTransactionConfirmed });
            if (ownerTransactionConfirmed) {
                resolve();
            }
        });
    }, [ownerTransactionConfirmed]);
    /**
     * wait for the user sign transaction callback and then dispatch awaiting owner transaction
     * to set the correct status while waiting for the token on chain
     */
    const waitForHandleTransaction = useCallback(async (transaction) => {
        if (!chainImplementation) {
            return;
        }
        logDebug('waitForHandleTransaction', { transaction, transactionExists: !!transaction });
        try {
            const signedTx = await chainImplementation.handleUserSignedTransaction(transaction);
            dispatch({ type: 'handleOwnerSendsTransactionComplete', signedTx });
        }
        catch (error) {
            const errorMessage = error && error instanceof Error ? error.message : 'ChainTransaction error';
            const errorCode = error === null || error === void 0 ? void 0 : error.errorCode;
            logError('waitForHandleTransaction error', { errorCode });
            const payload = Object.assign({}, (errorCode ? { errorCode } : {}));
            logError('waitForHandleTransaction (error instanceof ChainError)', errorCode);
            dispatch({ type: 'civicPass_owner_sends_chainTransactionError', payload });
            throw new ChainTransactionError(errorMessage);
        }
    }, [chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.initProps]);
    return {
        waitForTransactionConfirm,
        waitForHandleTransaction,
        handleWalletDisconnect,
    };
};
export default useWallet;
