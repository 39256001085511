import { useCallback, useEffect, useRef } from 'react';
import * as R from 'ramda';
import { prefixLogger } from '../logger';
import { CivicPassMessageAction, RefreshTokenState, ChainTransactionError, } from '../types';
import useChain from './useChain';
import useRefresh from './useRefresh';
import useGatekeeper from './useGatekeeper';
import useGatekeeperRecord from './useGatekeeperRecord';
import config, { chainConfig } from '../networkConfig';
import useCivicPass from './useCivicPass';
import useCivicPassEventListener from './useCivicPassEventListener';
import { hasExpired } from '../utils/tokenUtils';
import { AlreadyRefreshedError, throwIfAborted } from '../errors';
import { PassOrchestrator } from '../service/PassOrchestrator';
import useWallet from './useWalletHooks';
import { fetchInProgress, fetchNotStarted, hasFetchError } from '../useReducer/utils';
const logDebug = prefixLogger('useOrchestration').debug;
const logError = prefixLogger('useOrchestration').error;
/**
 * The orchestrator hook handles the main business logic of the component handling 2 main scenarios:
 * 1. the creation of a new gateway token for a new user
 * 2. the refreshing of an existing token for an existing user
 *
 * The orchestrator triggers uses effects to trigger flows for these two scenarios
 *
 * @param {{ wallet: WalletAdapter | undefined; clusterUrl: string; gatekeeperNetworkAddress: string | undefined; stage: string }} param0
 * @param {Partial<RootState>} state
 * @param {React.Dispatch<Action>} dispatch
 * @returns void
 */
const useOrchestration = ({ wallet, stage, chainImplementation, gatekeeperClient, }, state, dispatch) => {
    var _a;
    const { gatewayToken, walletToRefresh, civicPass, refreshTokenState, gatekeeperNetworkAddress, ownerSigns, chainType, } = state;
    const networkConfig = config({ gatekeeperNetworkAddress, stage, chainType });
    const chainConfiguration = chainConfig({ stage, chainType });
    // Register our hooks here
    const { waitForTransactionConfirm, waitForHandleTransaction } = useWallet(chainImplementation, wallet, state, dispatch);
    const { waitForGatekeeperIssuanceRequest, pollUntilNotRequested } = useGatekeeper({ wallet, stage, gatekeeperClient }, state, dispatch);
    const { checkForRefreshWithTimeout, refreshToken, waitForUnexpiredGatewayToken } = useRefresh({ stage, gatekeeperClient, networkConfig, chainConfig: chainConfiguration }, state, dispatch);
    useCivicPass({ wallet }, state, dispatch);
    useCivicPassEventListener({
        wallet,
        chainImplementation,
        instanceId: state.instanceId,
        iframeSrcUrl: state.iframeSrcUrl,
        dispatch,
    });
    /**
     * Handle any changes to the gateway token
     */
    useEffect(() => {
        if (gatewayToken) {
            logDebug('useEffect gatewayToken has changed in state', gatewayToken);
            dispatch({ type: 'civicPass_check_token_status', token: gatewayToken });
        }
    }, [gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.state, gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.owner, gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime]);
    /**
     * Handle any incoming changes from the gateway token listener: note that the gateway token may not
     * actually change, depending on the listener implementation
     */
    const onGatewayTokenCreatedOrChanged = (token) => {
        dispatch({ type: 'tokenChange', token });
    };
    const { addChainCreatedOrChangedListeners, addTokenChangeListener, removeOnChainListener, dispatchTokenExpectedTimerAdded, dispatchTokenExpectedTimerCleared, } = useChain({ chainImplementation, networkConfig, onGatewayTokenCreatedOrChanged }, state, dispatch);
    const { dispatchGatekeeperRecord, dispatchFailure: dispatchGatekeeperRecordFailure, getGatekeeperRecordResponseAction, } = useGatekeeperRecord({ wallet, gatekeeperClient }, dispatch);
    const stateInterface = {
        getState: () => state,
    };
    const dispatchInterface = {
        dispatch,
    };
    /**
     * Listens for callback or state changes that should trigger the orchestration issuance flow update
     */
    useEffect(() => {
        const abortController = new AbortController();
        PassOrchestrator.getInstance(stateInterface, dispatchInterface, abortController, {
            waitForGatekeeperIssuanceRequest,
            waitForTransactionConfirm,
            waitForHandleTransaction,
            dispatchTokenExpectedTimerAdded,
            dispatchTokenExpectedTimerCleared,
            onGatewayTokenCreatedOrChanged,
            addTokenChangeListener,
        }, chainImplementation, networkConfig, gatekeeperClient).orchestrate();
        return () => abortController.abort();
    }, [
        state.tokenIssuanceState,
        (_a = state.civicPass) === null || _a === void 0 ? void 0 : _a.responsePayload,
        waitForTransactionConfirm,
        waitForGatekeeperIssuanceRequest,
        // Adding waitForHandleTransaction as a dependency causes
        // loops when using handleTransaction
        //
        // waitForHandleTransaction,
        ownerSigns,
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.owner,
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.state,
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime,
    ]);
    /**
     * GatewayToken creation listener flow:
     * if the chainImplementation supports listening for a gatewayToken being created then
     *  - add a listener, removing an existing listener for any previous wallet addresses
     *  - the listener function will update state with any found or changed gateway token
     *  - and also initiate a timer to check for refresh when the token is approaching expiry
     */
    useEffect(() => {
        if (state.tokenCreatedOrChangedListenerId === undefined && (wallet === null || wallet === void 0 ? void 0 : wallet.publicKey)) {
            const addTokenCreatedOrChanged = async () => {
                if (!chainImplementation ||
                    !((wallet === null || wallet === void 0 ? void 0 : wallet.publicKey) && chainImplementation.addOnGatewayTokenCreatedOrChangedListener)) {
                    return;
                }
                addChainCreatedOrChangedListeners(onGatewayTokenCreatedOrChanged);
            };
            addTokenCreatedOrChanged().catch(logError);
        }
        return () => {
            if (state.tokenCreatedOrChangedListenerId !== undefined) {
                removeOnChainListener(state.tokenCreatedOrChangedListenerId);
            }
        };
    }, [wallet === null || wallet === void 0 ? void 0 : wallet.publicKey, chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.initProps, state.tokenCreatedOrChangedListenerId]);
    /**
     * Refresh Flows ----------------------------------------------------------------
     */
    /**
     * wait until we have a payload that has been emitted by the CivicPass iframe
     * then resolve the promise
     */
    const waitForCivicPassRefreshResponsePayload = useCallback(() => {
        logDebug('waitForCivicPassRefreshResponsePayload');
        const result = new Promise((resolve) => {
            const responsePayload = civicPass === null || civicPass === void 0 ? void 0 : civicPass.responsePayload;
            const refreshPayload = responsePayload && responsePayload[CivicPassMessageAction.REFRESH];
            logDebug('Refresh payload...', {
                refreshPayload,
                refreshPayloadState: refreshPayload === undefined,
            });
            if (refreshPayload !== undefined) {
                logDebug('Refreshing token...');
                resolve(refreshPayload);
            }
        });
        return result;
    }, [civicPass === null || civicPass === void 0 ? void 0 : civicPass.responsePayload]);
    /**
     * Refresh start flow !ownerSigns:
     * Triggered when we have a refreshResponse payload
     * wait refresh to be triggered from the iFrame
     * wait for the iframe to return a payload
     * wait for the user to confirm they've read the proof of ownership dialogue
     * wait for the user to provide proof of ownership
     * wait for a call to the gatekeeper to refresh the token
     * the rest of the flow is handled by the WAIT_FOR_ON_CHAIN useEffect below
     */
    useEffect(() => {
        const controller = new AbortController();
        if (!ownerSigns) {
            logDebug('useEffect: waitForCivicPassRefreshResponsePayload !ownerSigns');
            const walletToUse = walletToRefresh || wallet;
            if (!walletToUse || !walletToUse.publicKey) {
                return;
            }
            waitForCivicPassRefreshResponsePayload()
                .then(throwIfAborted(controller))
                .then(refreshToken(walletToUse))
                .then(throwIfAborted(controller))
                .then(() => dispatch({ type: 'refresh_token_success' }))
                .catch((error) => {
                if (controller.signal.aborted) {
                    return;
                }
                logError('refreshFlow', error);
                dispatch({ type: 'civicPass_refresh_failure' });
            });
        }
        // eslint-disable-next-line consistent-return
        return () => controller.abort();
    }, [civicPass === null || civicPass === void 0 ? void 0 : civicPass.responsePayload, ownerSigns]);
    /**
     * Refresh start flow ownerSigns:
     * Triggered when we have a refreshResponse payload
     * wait refresh to be triggered from the iFrame
     * wait for the iframe to return a payload
     * wait for the handle transaction callback
     * the rest of the flow is handled by the WAIT_FOR_ON_CHAIN useEffect below
     */
    useEffect(() => {
        const controller = new AbortController();
        if (ownerSigns) {
            logDebug('useEffect: waitForCivicPassRefreshResponsePayload ownerSigns');
            const walletToUse = walletToRefresh || wallet;
            if (!walletToUse || !walletToUse.publicKey) {
                return;
            }
            waitForCivicPassRefreshResponsePayload()
                .then(throwIfAborted(controller))
                .then(refreshToken(walletToUse))
                .then(throwIfAborted(controller))
                .then(waitForHandleTransaction)
                .then(throwIfAborted(controller))
                .then(() => dispatch({ type: 'refresh_token_success' }))
                .catch((error) => {
                if (controller.signal.aborted) {
                    return;
                }
                if (error instanceof AlreadyRefreshedError) {
                    dispatch({ type: 'refresh_token_success' });
                    return;
                }
                // chain transaction errors will be handled using a different flow and dispatch event
                if (!(error instanceof ChainTransactionError)) {
                    dispatch({ type: 'civicPass_refresh_failure' });
                }
                logError('ERROR tokenRefreshFlow ownerSigns', error);
            });
        }
        // eslint-disable-next-line consistent-return
        return () => controller.abort();
    }, [civicPass === null || civicPass === void 0 ? void 0 : civicPass.responsePayload, walletToRefresh === null || walletToRefresh === void 0 ? void 0 : walletToRefresh.publicKey, wallet === null || wallet === void 0 ? void 0 : wallet.publicKey, ownerSigns]);
    /**
     * Refresh complete flow:
     * Triggered by token change event triggered from a call to the gatekeeper to refresh the token
     * for owner signs case, dispatch an event to show the iframe chain-transaction confirming screen
     * wait until the token is unexpired and clear timeout if token is not expired
     * Complete the refresh event and clear the refresh response payload
     */
    useEffect(() => {
        const controller = new AbortController();
        if (!chainImplementation) {
            return;
        }
        if (refreshTokenState === RefreshTokenState.WAIT_FOR_ON_CHAIN) {
            logDebug('useEffect: Refresh complete flow', {
                expiryTime: gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime,
                hasExpired: (gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime) ? hasExpired(gatewayToken.expiryTime) : 'unknown',
            });
            // if the dApp handles the transaction we shouldn't assume that the final state is 'unexpired' token
            const optionalCheckUnexpired = chainImplementation.dAppHandlesTransactions && ownerSigns
                ? () => Promise.resolve()
                : waitForUnexpiredGatewayToken;
            optionalCheckUnexpired()
                .then(() => gatewayToken && checkForRefreshWithTimeout())
                .then(throwIfAborted(controller))
                .then(() => {
                dispatch({ type: 'refresh_complete' });
                dispatch({ type: 'civicPass_check_token_status', token: gatewayToken });
            })
                .catch((error) => {
                if (controller.signal.aborted) {
                    return;
                }
                logError('refreshFlow', error);
                dispatch({ type: 'civicPass_refresh_failure' });
            });
        }
        // eslint-disable-next-line consistent-return
        return () => controller.abort();
    }, [
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.identifier,
        gatewayToken === null || gatewayToken === void 0 ? void 0 : gatewayToken.expiryTime,
        refreshTokenState,
        walletToRefresh === null || walletToRefresh === void 0 ? void 0 : walletToRefresh.publicKey,
        wallet === null || wallet === void 0 ? void 0 : wallet.publicKey,
        ownerSigns,
    ]);
    const useHttpConfigRef = (newHttpConfig) => {
        const ref = useRef();
        // We have to perform a deep equality check, otherwise useEffect will run every time the httpConfig object reference changes.
        if (!R.equals(newHttpConfig, ref.current)) {
            ref.current = newHttpConfig;
        }
        return ref.current;
    };
    useEffect(() => {
        const abortController = new AbortController();
        logDebug('useEffect networkConfig.requiresGatekeeperRecordStatusCheck', {
            requiresGatekeeperRecordStatusCheck: networkConfig === null || networkConfig === void 0 ? void 0 : networkConfig.requiresGatekeeperRecordStatusCheck,
            gatekeeperClient,
            initConfig: gatekeeperClient === null || gatekeeperClient === void 0 ? void 0 : gatekeeperClient.initConfig,
            userInitiatedFlow: state.userInitiatedFlow,
            gatekeeperRecordState: state.gatekeeperRecordState,
            fetchNotStarted: fetchNotStarted(state),
            hasFetchError: hasFetchError(state),
        });
        // allow the user to re-initiate the flow if there was an error fetching state
        // on-chain or from the gatekeeper
        if (((gatekeeperClient === null || gatekeeperClient === void 0 ? void 0 : gatekeeperClient.initConfig) && networkConfig.requiresGatekeeperRecordStatusCheck && !state.userInitiatedFlow) ||
            (state.userInitiatedFlow && hasFetchError(state))) {
            dispatchGatekeeperRecord(abortController);
        }
        return () => abortController.abort();
    }, [
        state.userInitiatedFlow,
        JSON.stringify((gatekeeperClient === null || gatekeeperClient === void 0 ? void 0 : gatekeeperClient.initConfig) || {}),
        gatekeeperNetworkAddress,
        useHttpConfigRef(chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.httpConfig),
        networkConfig.requiresGatekeeperRecordStatusCheck,
    ]);
    useEffect(() => {
        const abortController = new AbortController();
        if (state === null || state === void 0 ? void 0 : state.pending) {
            logDebug('useEffect state.pending, starting polling', { pending: state === null || state === void 0 ? void 0 : state.pending });
            pollUntilNotRequested(abortController)
                .then((gatekeeperResponse) => {
                logDebug('useEffect pollUntilNotRequested, dispatching action...', { gatekeeperResponse });
                const action = getGatekeeperRecordResponseAction(gatekeeperResponse);
                dispatch(action());
            })
                .catch((error) => {
                logError('useEffect pollUntilNotRequested error', error);
                dispatch(dispatchGatekeeperRecordFailure());
            });
        }
        return () => {
            abortController.abort();
        };
    }, [state === null || state === void 0 ? void 0 : state.pending]);
    useEffect(() => {
        if (fetchInProgress(state)) {
            dispatch({ type: 'civicPass_check_in_progress' });
        }
    }, [state.gatekeeperRecordState, state.fetchOnChainStatus]);
};
export default useOrchestration;
